import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FetchDevtools } from '@hooks/useFetch';
import './App.css';
import { StyledToaster } from '@widgets/Toaster';
import { CurrentUserContextProvider } from '@hooks/useCurrentUserContext';
import Loader from '@widgets/Loader';
import { FilterContextProvider } from '@components/FilterSection/context/FilterContextProvider';
import Layout from './view/components/Layout/Layout';
import NotFound from './view/screens/NotFound/NotFound';
import ErrorBoundary from './view/components/ErrorBoundary';
import ProtectedRoutes from './ProtectedRoutes';
import Login from './view/screens/Login';
import ResponderLogin from './view/screens/Login/ResponderLogin';
import Room from './view/screens/Room';
import Authenticate from './view/screens/Authenticate';
import MeetingProfile from './view/screens/MeetingProfile';
import SOS from './view/screens/SOS';
import UserRoom from './view/screens/UserRoom';
import TwilioRoom from './view/screens/TwilioRoom';
import ManageProfileInfo from './view/screens/ManageInformation';
import OnboardingFormV2 from './view/screens/OnboardingForm';
import CoachHomeScreen from './view/screens/Provider/Home';
import ConsentCheckRoutes from './ConsentCheckRoutes';
import OnsiteEventHome from './view/screens/OnsiteEventHome/OnsiteEventHome';
import EventDetailsScreen from './view/screens/EventDetailsScreen';
import BookingManagement from './view/screens/SessionManagement';
import { CareNavHome } from './view/screens/CareNav';

function App() {
  return (
    <ErrorBoundary>
      <StyledToaster />
      <Router>
        <CurrentUserContextProvider>
          <FilterContextProvider>
            <Layout>
              <React.Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/responder-login" element={<ResponderLogin />} />
                  <Route path="/authenticate" element={<Authenticate />} />
                  <Route path="/sos/*" element={<SOS />} />
                  <Route path="/room" element={<Room />} />
                  <Route
                    path="/user/room/:meetingType/:meetingId"
                    element={<UserRoom />}
                  />
                  <Route path="/room/twilio" element={<TwilioRoom />} />
                  <Route element={<ProtectedRoutes />}>
                    <Route path="/onboarding" element={<OnboardingFormV2 />} />
                    <Route element={<ConsentCheckRoutes />}>
                      <Route path="/" element={<CoachHomeScreen />} />
                      <Route path="/notebook/" element={<CareNavHome />} />
                      <Route
                        path="/manage-profile"
                        element={<ManageProfileInfo />}
                      />
                      <Route
                        path="/providers/:role/:providerId"
                        element={<MeetingProfile />}
                      />
                      <Route path="/room" element={<Room />} />
                      <Route path="/onsite-days" element={<OnsiteEventHome />} />
                      <Route path="/onsite-days/:eventId" element={<EventDetailsScreen />} />
                      <Route path="/bookings" element={<BookingManagement />} />
                    </Route>
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </React.Suspense>
              <FetchDevtools />
            </Layout>
          </FilterContextProvider>
        </CurrentUserContextProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
