import { IBookMeetingRequest, IBookMeetingResponse } from '@customTypes/request/provider';
import { useMutation } from '@hooks/useFetch';
import { bookMeeting } from '@services/provider';

function useBooking(
  userId: string,
  {
    scheduledStartTime,
    duration,
    role,
    type,
    source,
    sessionId,
    count,
    interval,
    locationId,
    locationRef,
    userTimeZone,
  }: IBookMeetingRequest,
  { onSuccess, onError }: { onSuccess: (data: IBookMeetingResponse) => void; onError: (e: Error) => void },
) {
  return useMutation(
    () =>
      bookMeeting(userId, {
        scheduledStartTime,
        duration,
        role,
        type,
        source,
        sessionId,
        locationId,
        locationRef,
        ...(count && { count }),
        ...(interval && { interval }),
      }, { timezone: userTimeZone }),
    {
      onSuccess,
      onError,
    },
  );
}

export default useBooking;
