import moment from 'moment';
import {
  AvailableSessionDurations,
  IProviderOpenSlotsFullWithFrequency,
} from '@customTypes/response/provider';
import { ProviderTags } from '@constants/provider';
import { UserWithRoles } from '@hooks/useAuth/useAuth';
import { ApiDateFormat } from './Date';

export const getAvailableSlots = ({
  providerOpenSlots,
  selectedFrequency,
  selectedSessionDuration,
  selectedDate,
}: {
  providerOpenSlots: IProviderOpenSlotsFullWithFrequency | undefined;
  selectedSessionDuration: AvailableSessionDurations | null;
  selectedFrequency: string;
  selectedDate: string;
}) => {
  let slots: { label: string; value: string }[] = [];
  if (
    providerOpenSlots &&
    selectedSessionDuration &&
    providerOpenSlots[selectedFrequency] &&
    providerOpenSlots[selectedFrequency][selectedSessionDuration]
  ) {
    const availableSlotsForSelectedFrequency =
      providerOpenSlots[selectedFrequency];
    const availableSlots =
      availableSlotsForSelectedFrequency[selectedSessionDuration];

    slots = availableSlots
      .filter((slot) => slot.displayDate === selectedDate)
      .map(({ displayTime, startTimeUtc }) => ({
        label: displayTime,
        value: startTimeUtc.toString(),
      }));
  }

  return slots;
};

export const getAvailableSlotsDates = ({
  providerOpenSlots,
  selectedFrequency,
  selectedSessionDuration,
}: {
  providerOpenSlots: IProviderOpenSlotsFullWithFrequency | undefined;
  selectedSessionDuration: AvailableSessionDurations | null;
  selectedFrequency: string;
}) => {
  if (
    providerOpenSlots &&
    selectedSessionDuration &&
    selectedFrequency &&
    providerOpenSlots[selectedFrequency] &&
    providerOpenSlots[selectedFrequency][selectedSessionDuration]
  ) {
    return providerOpenSlots[selectedFrequency][selectedSessionDuration].map(
      (slot) => slot.displayDate,
    );
  }

  return [];
};

export const getSessionSummary = ({
  date,
  slot,
  duration,
  isValid,
}: {
  date: string;
  slot: string;
  duration: string;
  isValid: boolean;
}) => {
  let summary = '';
  if (isValid && date) {
    summary = summary.concat(moment(date, ApiDateFormat).format('dddd'));
  }
  if (isValid && slot && duration) {
    summary = summary
      .concat(', ')
      .concat(moment.unix(Number(slot)).format('hh:mm A'))
      .concat(' - ')
      .concat(moment.unix(Number(slot)).add(duration, 's').format('hh:mm A'));
  }
  return summary;
};

export const getCreditsummary = ({
  slot,
  sessionCount,
  duration,
  isValid,
}: {
  slot: string;
  sessionCount: Number;
  duration: string;
  isValid: boolean;
}) => {
  let creditSummary = '';
  if (isValid && slot && duration && sessionCount) {
    const startText = sessionCount === 1 ? ' ' : 'for ';
    const midText = sessionCount === 1 ? ' ' : sessionCount.toString();
    const endText =
      sessionCount === 1 ? ' ( 1 credit )' : ' sessions ( 4 credits )';
    creditSummary = creditSummary
      .concat(startText)
      .concat(midText)
      .concat(endText);
  }
  return creditSummary;
};

export const getProviderTags = (user: UserWithRoles) => {
  if (user.isEmployee) return ProviderTags.selfScheduled;
  if (user.isResponder || user.isCareNavigator)
    return ProviderTags.helplineReferral;

  return '';
};
