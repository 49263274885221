import { useFetch } from '@hooks/useFetch';
import { FetchKeys } from '@constants/queries';
import { getRoomsList } from '@services/meeting';
import { IRoomChangeOption } from '@customTypes/response/meeting';

function useRoomsList({
  locationId,
  meetingId,
}: {
  locationId?: string;
  meetingId: string;
}) {
  return useFetch<IRoomChangeOption[]>(
    [FetchKeys.roomsList, locationId, meetingId],
    () => getRoomsList(locationId || '', meetingId),
    {
      refetchOnWindowFocus: false,
      enabled: !!locationId,
    },
  );
}

export default useRoomsList;
