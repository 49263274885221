import * as React from 'react';
import { IProviderMeeting } from '@customTypes/response/meeting';
import { IProviderProfile, MeetingStatus } from '@customTypes/response/provider';
import useAuth from '@hooks/useAuth';
import { VideoRoomSDKs } from '@constants/App';
import Button from '@widgets/Button';
import { MeetingCancelButton } from './MeetingCancelButton';
import { MeetingConfirmButton } from './MeetingConfirmButton';
import { MeetingKebabMenu } from './MeetingKebabMenu';

interface ActionCTAsProps {
  meetingData: IProviderMeeting;
  providerProfile: IProviderProfile;
  isUpcomingMeeting: boolean;
  fetchMeetingJoinData: () => void;
}

export function ActionCTAs({
  meetingData,
  providerProfile,
  isUpcomingMeeting,
  fetchMeetingJoinData,
}: ActionCTAsProps) {
  const { status, type, id } = meetingData;
  const { user } = useAuth();

  if (user.isResponder) {
    return null;
  }

  if (status === MeetingStatus.userActionPending) {
    return (
      <div className="right-section">
        <MeetingCancelButton
          meetingData={meetingData}
          providerName={providerProfile.name}
        />
        <MeetingConfirmButton
          meetingId={id}
          providerName={providerProfile.name}
        />
      </div>
    );
  }

  if (
    user.isCareNavigator
      ? type === VideoRoomSDKs.intellectClinicOffsite
      : type === VideoRoomSDKs.dailyCo
  ) {
    return (
      <div className="right-section">
        {user.isCareNavigator ? null : (
          <Button
            label="Join"
            variant="secondary"
            onClick={() => fetchMeetingJoinData()}
            disabled={!isUpcomingMeeting} // need to disable joining recurring meetings
          />
        )}

        <MeetingKebabMenu
          meetingData={meetingData}
          providerProfile={providerProfile}
        />
      </div>
    );
  }

  return null;
}
