import { IBookMeetingResponse } from '@customTypes/request/provider';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';
import NetworkClient from './Network';

const client = new NetworkClient();

export const updateRoom = async ({
  meetingId,
  roomId,
  newRoomId,
}: {
  meetingId: string;
  roomId: string;
  newRoomId: string;
}): Promise<IBookMeetingResponse> => {
  const response = await client.doPut(
    beautifyUrl(Config.api.careNavigator.updateRoom, [meetingId]),
    { roomId, newRoomId },
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(
    response.error?.message || 'Unable to update room, please try again!',
  );
};
