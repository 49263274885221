import * as React from 'react';
import './RadioGroup.scss';

interface RadioGroupProps {
  name: string;
  values: {
    value: string;
    label: string;
  }[];
  selectedValue: string;
  onChange: (value: string) => void;
  variant?: 'list' | 'box'
}

function RadioGroup({ name, values, onChange, selectedValue, variant }: RadioGroupProps) {
  return (
    <div className={`radio-groups ${variant}`}>
      {values.map(({ value, label }) => (
        <div className={`radio-item ${variant}`}>
          <input
            key={value}
            type="radio"
            value={value}
            name={name}
            id={`${name}-${value}`}
            onChange={() => onChange(value)}
            checked={value === selectedValue}
          />
          <label className={variant} htmlFor={`${name}-${value}`}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
