// import SessionSchedulePopup from '@components/SessionSchedule';
import RecurringSessionSchedulePopup from '@components/SessionSchedule/RecurringSessionSchedulePopup';
import { RecurringSessionSuccessData } from '@components/SessionSchedule/types';
import { BookSessionSources } from '@constants/analytics';
import { FetchKeys } from '@constants/queries';
import { IProviderMeeting } from '@customTypes/response/meeting';
import { IProviderProfile } from '@customTypes/response/provider';
import useAuth from '@hooks/useAuth';
import { ApiDateFormat, getTimeZoneAbbreviation } from '@utilities/common/Date';
import { getImageUri } from '@utilities/common/Path';
import Button from '@widgets/Button';
import Typography from '@widgets/Typography';
import moment from 'moment';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import UserRecurringSessionModal from '@components/SessionSchedule/UserRecurringSessionModal';
import UserRecurringBookingSuccess from '@components/SessionSchedule/UserRecurringSessionModal/UserReccurringBookingSuccess';
// import BookedPopup from '../../Provider/Home/ProviderList/BookedPopup';
import useFilterContext from '@components/FilterSection/context/useFilterContext';
import { useTranslation } from 'react-i18next';
import BookedRecurringSessionPopup from '../../Provider/Home/ProviderList/BookedPopup/BookedRecurringSessionPopup';
import { ConfirmationModal } from '../../Provider/Home/ProviderList/Constant/ProviderListConstant';
import { IBookingConfirmationData } from '../../Provider/Home/ProviderList/types';
import UnmatchPopup from '../UnmatchPopup';
import { ProfileBio } from './ProfileBio';

interface ProfileDetailsProps {
  providerProfile: IProviderProfile;
  currentMeetings: IProviderMeeting[] | undefined;
  isMatchedProvider: boolean;
}

export function ProfileDetails({
  providerProfile,
  currentMeetings,
  isMatchedProvider,
}: ProfileDetailsProps) {
  const [showUnmatchPopup, setShowUnmatchPopup] =
    React.useState<boolean>(false);
  const [showSchedulePopup, setShowSchedulePopup] =
    React.useState<boolean>(false);
  const [bookingConfirmationData, setBookingConfirmationData] =
    React.useState<IBookingConfirmationData>({
      showSuccessModal: ConfirmationModal.NoModal,
    });

  const { filterData } = useFilterContext();
  const { t } = useTranslation();

  const [
    recurringSessionBookingSuccessData,
    setRecurringSessionBookingSuccessData,
  ] = React.useState<Omit<RecurringSessionSuccessData, 'hasBooked'>>({
    showSuccessModal: false,
    nextSessionOn: '',
    frequencyLabel: '',
    sessionsCount: '',
  });

  const queryClient = useQueryClient();
  const { user } = useAuth();

  const areSlotsAvailable = Boolean(
    providerProfile?.availableSlots && providerProfile?.availableSlots.length,
  );

  const getNextAvailabilityLabel = () => {
    let availabilityLabel = 'No available slots at the moment';
    let nextAvailableSlot = null;
    if (areSlotsAvailable) {
      nextAvailableSlot = { ...providerProfile.availableSlots[0] };
    }
    // else if (providerProfile.nextSlot) {
    //   nextAvailableSlot = { ...providerProfile.nextSlot };
    // }

    if (nextAvailableSlot) {
      availabilityLabel = `Next availability on ${moment(
        nextAvailableSlot.displayDate,
        ApiDateFormat,
      ).format('ddd DD MMM')}, ${
        nextAvailableSlot.displayTime
      } ${getTimeZoneAbbreviation()}`;
    }
    return availabilityLabel;
  };

  const onUnmatchPopupClose = (unmatched: boolean) => {
    setShowUnmatchPopup(false);
    if (unmatched) {
      queryClient.invalidateQueries([FetchKeys.providerHome]);
    }
  };

  const onSchedulePopupClose = (hasBooked: boolean, bookingData: any) => {
    setShowSchedulePopup(false);
    if (hasBooked) {
      setBookingConfirmationData({
        showSuccessModal: ConfirmationModal.selfRecurringSession,
        nextSessionOn: bookingData?.scheduledDate,
        sessionsCount: bookingData?.count,
        prviderId: bookingData?.providerId,
        scheduledStartTime: bookingData?.scheduledStartTime,
        scheduledEndTime: bookingData?.scheduledEndTime,
        providerName: bookingData?.providerName,
        profileImage: bookingData?.profileImage,
        providerRole: bookingData?.providerRole,
        interval: bookingData?.interval,
      });
      queryClient.invalidateQueries([FetchKeys.providerHome]);
    }
  };

  const onBookingSuccessPopupClose = () => {
    setBookingConfirmationData({ showSuccessModal: ConfirmationModal.NoModal });
    if (user.isEmployee) {
      queryClient.invalidateQueries([FetchKeys.providerHome]);
    }
  };

  const onRecurringSessionSchedulePopupClose = ({
    hasBooked,
    nextSessionOn,
    frequencyLabel,
    sessionsCount,
    location,
  }: Omit<RecurringSessionSuccessData, 'showSuccessModal'>) => {
    if (hasBooked) {
      setRecurringSessionBookingSuccessData({
        nextSessionOn,
        frequencyLabel,
        sessionsCount,
        location,
        showSuccessModal: true,
      });
      queryClient.invalidateQueries([FetchKeys.providerHome]);
    }
  };

  return (
    <div className="profile-details">
      <div className="details-general">
        <img
          className="profile-img"
          src={getImageUri(providerProfile.profileImage || '')}
          alt="name"
        />
        <div className="details-top-section">
          <div className="left-section">
            <div className="basic-info">
              <div className="basic-info-name">
                <Typography size={18} weight="500">
                  {providerProfile.name}
                </Typography>
                {providerProfile?.provider.leave?.onLeave ? (
                  <div className="on-leave-badge">{t('ON_LEAVE')}</div>
                ) : null}
              </div>
              {!currentMeetings?.length && (
                <Typography
                  size={12}
                  weight="500"
                  color={areSlotsAvailable ? 'primary' : 'error'}
                >
                  {getNextAvailabilityLabel()}
                </Typography>
              )}
            </div>
          </div>
          <div className="right-section">
            {isMatchedProvider && (
              <Button
                label="Unmatch"
                variant="secondary"
                onClick={() => setShowUnmatchPopup(true)}
              />
            )}
            {(!currentMeetings?.length || user.isCareNavigator) && (
              <Button
                label="Book a session"
                disabled={!areSlotsAvailable}
                variant="primary"
                onClick={() => setShowSchedulePopup(true)}
              />
            )}
          </div>
        </div>
      </div>

      <ProfileBio bio={providerProfile.bio} />

      {showUnmatchPopup && providerProfile.providerId && (
        <UnmatchPopup
          providerId={providerProfile.providerId.toString()}
          onClose={(isUnmatched) => onUnmatchPopupClose(isUnmatched)}
        />
      )}
      {showSchedulePopup && providerProfile && user.isEmployee && (
        // <SessionSchedulePopup
        //   source={BookSessionSources.providerProfile}
        //   role={currentConversation?.providerRole || filterData.providerRole}
        //   providerData={providerProfile}
        //   closeHandler={(hasBooked) => onSchedulePopupClose(hasBooked)}
        //   isProviderMatched={isMatchedProvider()}
        // />
        <UserRecurringSessionModal
          closeHandler={onSchedulePopupClose}
          role={filterData.providerRole}
          providerData={providerProfile}
          source={BookSessionSources.providerProfile}
          isProviderMatched={isMatchedProvider}
        />
      )}
      {showSchedulePopup && providerProfile && !user.isEmployee && (
        <RecurringSessionSchedulePopup
          source={BookSessionSources.providerProfile}
          role={filterData.providerRole}
          providerData={providerProfile}
          isProviderMatched={isMatchedProvider}
          closeHandler={(bookingData) => {
            setShowSchedulePopup(false);
            onRecurringSessionSchedulePopupClose(bookingData);
          }}
        />
      )}
      {user.isResponder ||
        (user.isCareNavigator &&
          recurringSessionBookingSuccessData.showSuccessModal && (
          <BookedRecurringSessionPopup
            frequencyLabel={recurringSessionBookingSuccessData.frequencyLabel}
            sessionsCount={recurringSessionBookingSuccessData.sessionsCount}
            upcomingSessionOn={
              recurringSessionBookingSuccessData.nextSessionOn
            }
            location={recurringSessionBookingSuccessData.location}
            onClose={() =>
              setRecurringSessionBookingSuccessData({
                showSuccessModal: false,
              })
            }
          />
        ))}
      {bookingConfirmationData.showSuccessModal ===
        ConfirmationModal.selfRecurringSession &&
        user.isEmployee && (
      // <BookedPopup onClose={() => onBookingSuccessPopupClose()} />
        <UserRecurringBookingSuccess
          confirmationData={bookingConfirmationData}
          closeHandler={onBookingSuccessPopupClose}
        />
      )}
    </div>
  );
}
