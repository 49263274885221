import {
  EventNames,
  EventActions,
  EventCategories,
} from '@constants/analytics';

import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import Button from '@widgets/Button';
import Popup from '@widgets/Popup';
import TextArea from '@widgets/TextArea';
import Typography from '@widgets/Typography';
import * as React from 'react';
import toast from 'react-hot-toast';
import useCancellation from '../hooks/useCancellation';
import './SessionCancelPopup.scss';

interface SessionCancelPopupProps {
  providerName: string;
  meetingId: string;
  refetchUserMeeting: () => void;
  onClose: () => void;
}

function SessionCancelPopup({
  providerName,
  meetingId,
  onClose,
  refetchUserMeeting,
}: SessionCancelPopupProps) {
  const [cancelNote, setCancelNote] = React.useState<string>('');

  const { track } = useTracking();

  const { mutate: cancelSessionRequest } = useCancellation({
    onSuccess: () => {
      onClose();
      refetchUserMeeting();
      toast(`You have cancelled the session request with ${providerName}`);

      track(EventNames.cancelSessionConfirmed, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.cancelSessionConfirmed,
        eventLabel: 'cancel_session_confirmed',
        userType: Analytics.getUserType(),
      });
    },
    onError: (error) => {
      toast(error.message);
    },
  });

  React.useEffect(() => {
    track(EventNames.cancelSessionRequest, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.cancelSessionRequest,
      eventLabel: 'cancel_session_request',
      userType: Analytics.getUserType(),
    });
  }, []);

  return (
    <Popup onClose={onClose}>
      <div className="session-cancel-container">
        <Typography size={24} weight="500">
          Are you sure you want to cancel?
        </Typography>
        <Typography size={16} weight="400">
          Let us know what went wrong
        </Typography>
        <div className="notes-container">
          <TextArea
            placeholder="Let us know what went wrong..."
            value={cancelNote}
            onChange={(value) => setCancelNote(value)}
          />
        </div>
        <div className="cancel-action">
          <Button
            disabled={!cancelNote}
            width="100%"
            label="Cancel"
            fontSize={14}
            onClick={() =>
              cancelSessionRequest({ meetingId, note: cancelNote })
            }
          />
        </div>
      </div>
    </Popup>
  );
}

export default SessionCancelPopup;
