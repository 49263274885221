import { CancellationPolicyData } from './types';

export const CancellationPolicyFAQ1: CancellationPolicyData[] = [
  {
    id: 1,
    query: 'Do I need to install any software?',
    response:
      'No. You don’t need to install any software, the call will be hosted on our website.',
  },
  {
    id: 2,
    query: 'How do I join?',
    response:
      'Select “Join session” on the main page after logging into this website.',
  },
  {
    id: 3,
    query: 'When should I join?',
    response:
      'We recommend joining 5 minutes ahead of time so that you can gather your thoughts and have a productive session.',
  },
];

export const CancellationPolicyFAQ2: CancellationPolicyData[] = [
  {
    id: 1,
    query: 'How should I prepare for the call?',
    response:
      "Before your first call with your coach, write down specific issues you'd like to address. For example, you might want to focus on 'building a better relationship with my team' or 'overcoming procrastination.' Your coach will guide you through the issues you’ve identified. ",
  },
  {
    id: 2,
    query: 'What if I can’t make it?',
    response:
      "We get it, life happens. If you need to reschedule or cancel, do it at least 24 hours before your session to get your credit returned. Cancelling within 24 hours means you won't get your credit back",
  },
];

export const TagText: Record<string, string> = {
  '0': 'Intellect coach',
  '1': 'Intellect clinician',
};
