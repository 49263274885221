import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import { IProviderProfile } from '@customTypes/response/provider';
import { useProviderData } from '@hooks/useProviderData/useProviderData';
import { AllProvidersCountryId, ProviderAttributes } from '@constants/provider';
import { BookSessionSources } from '@constants/analytics';
import UserRecurringBookingSuccess
  from '@components/SessionSchedule/UserRecurringSessionModal/UserReccurringBookingSuccess';
import Loader from '@widgets/Loader';
import { CoachFlow, ConfirmationModal } from '../../Provider/Home/ProviderList/Constant/ProviderListConstant';
import SpecialCase from '../../Provider/Home/ProviderList/SpecialCase/SpecialCase';
import F2FBooking from '../../Provider/Home/ProviderList/F2FBooking';
import ProviderProfileCard from '../../Provider/Home/ProviderList/ProviderProfileCard';
import RecurringSessionSchedulePopup from '../../../components/SessionSchedule/RecurringSessionSchedulePopup';
import { IBookingConfirmationData, ProviderListScreenProps } from '../../Provider/Home/ProviderList/types';
import BookedRecurringSessionPopup from '../../Provider/Home/ProviderList/BookedPopup/BookedRecurringSessionPopup';
import useFilterContext from '../../../components/FilterSection/context/useFilterContext';
import ResponderErrorView from '../../ResponderErrorView';

import '../../Provider/Home/ProviderList/ProviderList.scss';
import FilterSection from '../FilterSection/FilterSection';

export function ProviderListScreen({
  refetchProviderHome,
}: ProviderListScreenProps) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const {
    currentUserData: { userData },
  } = useCurrentUserContext();
  const { filterData, headerRef } = useFilterContext();
  const [selectedProvider, setSelectedProvider] =
    React.useState<IProviderProfile | null>(null);
  const [bookingConfirmationData, setBookingConfirmationData] =
    React.useState<IBookingConfirmationData>({
      showSuccessModal: ConfirmationModal.NoModal,
    });

  const { providerData, isProviderDataLoading } = useProviderData({
    engagement: filterData.meetingType,
    providerRole: filterData.providerRole,
    goals: filterData.goals,
    languages: [filterData.languages],
    userId: user.id,
    ...(filterData.providerSelectedCountry !== AllProvidersCountryId && {
      providerSelectedCountry: [filterData.providerSelectedCountry],
    }),
    enabled: !!filterData.providerRole.length,
  });

  const filterProviderData = React.useMemo(() => {
    if (
      providerData &&
      filterData.employeeSelectedCountry !== 'all_locations'
    ) {
      const filteredProviders = providerData.filter(({ attributes }) => {
        const providerLocations = attributes.filter(
          (attr) => attr.type === ProviderAttributes.LOCATION,
        );
        const locationNames = providerLocations.map(
          (location) => location.name,
        );

        if (locationNames && providerLocations.length) {
          if (locationNames.includes(filterData.employeeSelectedCountry)) {
            return true;
          }
        }

        return false;
      });
      return filteredProviders;
    }
    return providerData;
  }, [filterData.employeeSelectedCountry, providerData]);

  const CurrentFlowType = React.useMemo(() => {
    if (!userData?.addOns?.clinicalEnabled && !userData?.addOns?.coachEnabled) {
      return CoachFlow.Error;
    }

    if (!providerData?.length && !isProviderDataLoading) {
      return CoachFlow.NoProviderToShow;
    }

    if (
      (filterData.country.code === 'CN')
    ) {
      return CoachFlow.SpecialCase;
    }
    if (filterData.meetingType === '1') {
      return CoachFlow.F2F;
    }
    return CoachFlow.Default;
  }, [user, filterData, userData, providerData]);

  useEffect(() => {
    if (!(user.isResponder || user.isCareNavigator)) {
      // navigate('/'); TODO
    }
  }, []);

  const getHeight = React.useMemo(
    () => `calc(100vh - ${(headerRef?.current?.clientHeight || 0) + 90}px)`,
    [headerRef],
  );
  return (
    <div>
      <FilterSection isProfile={false} />
      <article className="list-section">
        {bookingConfirmationData.showSuccessModal ===
          ConfirmationModal.selfRecurringSession && (
          <UserRecurringBookingSuccess
            confirmationData={bookingConfirmationData}
            closeHandler={() => {
              setBookingConfirmationData({
                showSuccessModal: ConfirmationModal.NoModal,
              });
              refetchProviderHome();
            }}
          />
        )}
        {bookingConfirmationData.showSuccessModal ===
          ConfirmationModal.recurringSession && (
          <BookedRecurringSessionPopup
            frequencyLabel={bookingConfirmationData.frequencyLabel}
            sessionsCount={bookingConfirmationData.sessionsCount}
            upcomingSessionOn={bookingConfirmationData.nextSessionOn}
            location={bookingConfirmationData.location}
            onClose={() => {
              setBookingConfirmationData({
                showSuccessModal: ConfirmationModal.NoModal,
              });
              refetchProviderHome();
            }}
          />
        )}
        {CurrentFlowType === CoachFlow.Error && (
          <ResponderErrorView descriptionToDisplay={t('noServicesErrMsg')} />
        )}
        {CurrentFlowType === CoachFlow.NoProviderToShow && (
          <ResponderErrorView
            descriptionToDisplay={t('noSuitableProviderFound')}
          />
        )}
        {CurrentFlowType === CoachFlow.SpecialCase && <SpecialCase />}
        {CurrentFlowType === CoachFlow.F2F && (
          <F2FBooking refetchProviderHome={refetchProviderHome} />
        )}
        {CurrentFlowType === CoachFlow.Default && isProviderDataLoading && <Loader />}
        {CurrentFlowType === CoachFlow.Default && (
          <div className="provider-listing" style={{ height: getHeight }}>
            {filterProviderData?.map((profile, index) => (
              <ProviderProfileCard
                index={index}
                key={profile.providerId}
                profileData={profile}
                onBook={() => setSelectedProvider(profile)}
                role={filterData.providerRole}
              />
            ))}
          </div>
        )}
        {selectedProvider && (
          <RecurringSessionSchedulePopup
            source={BookSessionSources.providerListing}
            role={filterData.providerRole}
            providerData={selectedProvider}
            closeHandler={(bookingData) => {
              setSelectedProvider(null);
              setBookingConfirmationData({
                ...bookingData,
                showSuccessModal: bookingData.hasBooked
                  ? ConfirmationModal.recurringSession
                  : ConfirmationModal.NoModal,
              });
            }}
            isProviderMatched={false}
          />
        )}
      </article>
    </div>
  );
}
