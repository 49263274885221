import React from 'react';
import useFilterContext from '@components/FilterSection/context/useFilterContext';
import useAuth from '@hooks/useAuth';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import Loader from '../../widgets/Loader';
import useProviderHome from '../Provider/Home/hooks/useProviderHome';
import { CareNavProviderProfile } from './ProviderProfile';
import { ProviderListScreen } from './ProviderList/ProviderList';

export function CareNavHome() {
  const { user } = useAuth();
  const {
    currentUserData: { isLoading: isUserDataLoading },
  } = useCurrentUserContext();

  const { filterData } = useFilterContext();

  const { data, refetch, isLoading } = useProviderHome(user.id);

  if (isLoading || isUserDataLoading) {
    return <Loader />;
  }

  if (data?.conversations?.length && !!data.conversations.find(convo => convo.providerRole === filterData.providerRole)) {
    return <div>
      <CareNavProviderProfile
        providerRole={filterData.providerRole}
        providerProfile={data.conversations.find(convo => convo.providerRole === filterData.providerRole)?.provider}
        currentMeetings={data.meetings}
      />
    </div>;
  }
  return <ProviderListScreen refetchProviderHome={refetch} />;
}

// Step1: Fetch Coach home
// Step2: If conversations present and provider role = filter then show provider, otherwise fetch provider list
// On filter change, Check 2


