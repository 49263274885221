import { ProviderMatchRequest } from '@customTypes/request/provider';
import { useMutation } from '@hooks/useFetch';
import { matchWithProvider } from '@services/provider';

function useProviderMatching(
  {
    userId,
    providerId,
    tags,
    friendlyName,
    role,
    providerCode,
    userTimeZone,
  }: {
    userId: string;
    tags: string;
    friendlyName: string;
    role: string;
    providerId?: number;
    providerCode?: string;
    userTimeZone?: string;
  },
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
) {

  let matchRequest: ProviderMatchRequest = { friendlyName, tags, role };
  if (providerCode) {
    matchRequest = { ...matchRequest, providerCode };
  } else {
    matchRequest = { ...matchRequest, providerId };
  }

  return useMutation(
    () =>
      matchWithProvider(userId, matchRequest, { timezone: userTimeZone }),
    {
      onSuccess,
      onError,
    },
  );
}

export default useProviderMatching;
