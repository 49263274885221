import { FetchKeys } from '@constants/queries';
import { IProviderHome } from '@customTypes/response/meeting';
import { useFetch } from '@hooks/useFetch';
import { getProviderHome } from '@services/provider';

function useProviderHome(userId: string) {
  return useFetch<IProviderHome>(
    [FetchKeys.providerHome, userId],
    () => getProviderHome(userId),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export default useProviderHome;
