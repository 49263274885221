import Typography from '@widgets/Typography';
import * as React from 'react';

function SpecialCase() {
  return (
    <div className="special-case">
      <Typography size={24} weight="500">
        Need to schedule a session?
      </Typography>
      <div className="description">
        <Typography size={14} weight="400" color="secondary" display="inline">
          For employees based in T&S China offices, kindly email&nbsp;
        </Typography>
        <Typography
          withUnderline
          size={14}
          weight="600"
          color="primary"
          display="inline"
        >
          tiktok@intellect.co
        </Typography>
        <Typography size={14} weight="400" color="secondary" display="inline">
          &nbsp;to schedule your session instead. Please note that only virtual
          sessions are available.
        </Typography>
      </div>
    </div>
  );
}

export default SpecialCase;
