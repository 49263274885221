import * as React from 'react';
import useAuth from '@hooks/useAuth';
import Typography from '@widgets/Typography';
import SelectBox from '@widgets/SelectBox';
import MultiSelect from '@widgets/MultiSelect';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import {
  InPersonProviderRoleItems,
  ProviderRoles,
  VirtualProviderRoleItems,
} from '@constants/provider';
import { ITimezoneByCountry, TimezoneByCountry } from '@constants/timezones';
import useFilterContext from '@components/FilterSection/context/useFilterContext';
import { getInitialRole } from '@components/FilterSection/context/FilterContextProvider';
import { UserWithRoles } from '@hooks/useAuth/useAuth';
import filterIcon from '@assets/filter.svg';
import { IFilterDataType } from '../../Provider/Home/ProviderList/types';

import {
  MeetingTypeList,
} from '../../Provider/Home/ProviderList/Constant/ProviderListConstant';
import useMatchConfig from '../../Provider/Home/hooks/useMatchConfig';
import useGetProviderCountriesList from '../../Provider/Home/hooks/useGetProviderCountriesList';

import './FilterSection.scss';

function CareNavFilters({ user, filterData, onFilterChange }: {
  filterData: IFilterDataType,
  onFilterChange: (data: Partial<IFilterDataType>) => void,
  user: UserWithRoles
}) {
  const { data: matchConfig } = useMatchConfig(true);

  const {
    data: providerCountriesList,
    isFetching: isProviderCountriesListFetching,
  } = useGetProviderCountriesList(
    user.id,
    {
      engagement: filterData.meetingType || '0',
      role: [filterData.providerRole || '0'],
    },
    true,
  );

  const getCountryDataFromCountryCode = (code: string) =>
    TimezoneByCountry.find((c) => c.code === code);

  return <>
    <MultiSelect
      enableSelectboxSearch
      options={
        matchConfig?.goals.map((l) => ({
          label: l.areaExpertise,
          key: l.id,
        })) || []
      }
      initialValues={filterData.goals}
      onClose={(selectedValues) =>
        onFilterChange({ goals: selectedValues })
      }
      placeholder="Goals"
      maxSelection={3}
    />
    <SelectBox
      values={
        matchConfig?.languages.map((l) => ({
          label: l.name,
          value: l.id,
        })) || []
      }
      onChange={(value) => onFilterChange({ languages: value })}
      selectedValue={filterData.languages}
    />
    <SelectBox
      disabled={isProviderCountriesListFetching}
      id="filter-provider-country"
      selectedValue={String(filterData.providerSelectedCountry)}
      values={
        providerCountriesList
          ? providerCountriesList.map((t) => ({
            label: t.countryName,
            value: String(t.countryId),
          }))
          : []
      }
      onChange={(value) =>
        onFilterChange({ providerSelectedCountry: Number(value) })
      }
      minWidth="150px"
    />
    <SelectBox
      id="filter-country"
      selectedValue={filterData.country.code}
      values={TimezoneByCountry.map((t) => ({
        label: t.name,
        value: t.code,
      }))}
      onChange={(value) => {
        const country = getCountryDataFromCountryCode(
          value,
        ) as ITimezoneByCountry;
        onFilterChange({
          country,
          timezone: country.timezones[0],
        });
      }}
    />
    <SelectBox
      id="filter-country-timezone"
      selectedValue={filterData.timezone}
      values={filterData.country.timezones.map((t) => ({
        label: t,
        value: t,
      }))}
      onChange={(value) => onFilterChange({ timezone: value })}
    />
  </>;
}

export default function FilterSection({ isProfile }: { isProfile: boolean }) {
  const { user } = useAuth();
  const {
    currentUserData: { userData, currentUserConfig, isLoading: isUserLoading },
  } = useCurrentUserContext();
  const {
    filterData,
    updateFilterData,
    isRoleFilterDisabled,
    headerRef,
  } = useFilterContext();

  const [filtersExpanded, setFiltersExpanded] = React.useState(true);

  const AvailableMeetingType = React.useMemo(() => {
    // if (userData?.config?.ALLOW_F2F && user.isCareNavigator) {
    //   return MeetingTypeList;
    // }
    const enabledMeetingType = ['0'];
    if (userData?.config?.ALLOW_F2F) enabledMeetingType.push('1');
    if (user.isCareNavigator) enabledMeetingType.push('10');

    return MeetingTypeList.filter((item) =>
      enabledMeetingType.includes(item.value),
    );
  }, [userData]);
  const onFilterChange = (data: Partial<IFilterDataType>) => {
    updateFilterData({
      filterData: {
        ...filterData,
        ...data,
        ...(data?.meetingType && {
          providerRole:
            data.meetingType === ProviderRoles.INTELLECTCLINICOFFSITE
              ? ProviderRoles.INTELLECTCLINICOFFSITE
              : getInitialRole(userData),
        }),
      },
    });
  };

  const availableProviderRole = React.useMemo(() => {
    let filters = VirtualProviderRoleItems;
    if (
      user.isCareNavigator &&
      filterData.meetingType === ProviderRoles.INTELLECTCLINICOFFSITE
    ) {
      filters = InPersonProviderRoleItems;
    } else {
      if (!userData?.addOns?.coachEnabled) {
        filters = VirtualProviderRoleItems.filter(
          (f) => f.value !== ProviderRoles.COACHING,
        );
      }
      if (!userData?.addOns?.clinicalEnabled) {
        filters = filters.filter((f) => f.value !== ProviderRoles.CLINICAL);
      }
    }
    return filters;
  }, [userData, filterData.meetingType]);

  return (
    <div
      ref={headerRef}
      className={`header-section ${
        !isProfile ? '' : 'provider-profile'
      }`}
    >
      <div className="left-headers">
        <Typography weight="500" color="primary" size={24}>
          Select a provider to start your journey
        </Typography>
        <Typography weight="400" color="secondary" size={16}>
          (Session booking for {user.email})
        </Typography>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={`filters-collapsible-toggle ${
          filtersExpanded ? 'open' : 'closed'
        }`}
        onClick={() => setFiltersExpanded(!filtersExpanded)}
      >
        <div className="title">Filters</div>
        <img src={filterIcon} alt="expand filters" />
      </div>
      <div
        className={`filters-and-search ${filtersExpanded ? 'open' : 'closed'}`}
      >
        {currentUserConfig?.showMeetingTypeFilter && (
          <SelectBox
            id="filter-meetingType"
            disabled={isRoleFilterDisabled}
            selectedValue={filterData.meetingType}
            onChange={(value) => onFilterChange({ meetingType: value })}
            values={AvailableMeetingType}
          />
        )}
        {!isUserLoading && (
          <SelectBox
            id="filter-provider-role"
            disabled={isRoleFilterDisabled}
            selectedValue={filterData.providerRole}
            onChange={(value) => {
              onFilterChange({ providerRole: value });
              // onRoleChange?.(value);
            }}
            values={availableProviderRole}
          />
        )}
        {!isProfile && (
          <CareNavFilters user={user} onFilterChange={onFilterChange} filterData={filterData} />
        )}
      </div>
    </div>
  );
}
