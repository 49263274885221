import { RecurringSessions } from '@constants/user';
import { IResponse } from './IResponse';

export type AvailableSessionDurations = '900' | '1800' | '2700' | '3600';
export type RecurringSessionFrequencies =
  typeof RecurringSessions.availableRecurringSessionOptions[keyof typeof RecurringSessions.availableRecurringSessionOptions];

export const MeetingStatus = {
  userActionPending: '9',
} as const;
export type ProviderOpenSlots = {
  displayDate: string;
  displayTime: string;
  displayTimeEnd: string;
  startTimeUtc: number;
  endTimeUtc: number;
};
export type IProviderOpenSlotsFull = Record<
AvailableSessionDurations,
ProviderOpenSlots[]
>;
export type IProviderOpenSlotsFullWithFrequency = Record<
RecurringSessionFrequencies,
Record<AvailableSessionDurations, ProviderOpenSlots[]>
>;
export type IProviderOpenSlotsResponse = IResponse<IProviderOpenSlotsFull>;

export interface IProviderAttribute {
  id: number;
  isActive: boolean;
  name: string;
  type: number;
}

export interface IMeeting {
  id: string;
  providerId: number;
  displayDate: number;
  displayTime: number;
  scheduledEndTime: number;
  displayTimeEnd: number;
  locationRef: string;
  type: string; // TODO link to meeting SDK types
  status: typeof MeetingStatus[keyof typeof MeetingStatus];
  meetingTimeZone: string;
}

interface AvailableSlot {
  displayDate: string;
  displayTime: string;
}

export interface IProviderProfile {
  bio: string;
  // bioTranslation: ProviderTranslationObject;
  name: string;
  // providerNameTranslation: ProviderTranslationObject;
  profileImage: string;
  providerId: number;
  rating: number;
  attributes: Array<IProviderAttribute>;
  languages: Array<{
    name: string;
  }>;
  availableSlots: AvailableSlot[];
  profileVideo: string;
  provider: {
    role: string;
    leave: {
      onLeave: boolean;
      from?: number;
      to?: number;
    };
  };
  leave: {
    onLeave: boolean;
    from?: number;
    to?: number;
  };
  nextSlot: AvailableSlot;
}

export type IProviderCountry = {
  countryId: number;
  countryName: string;
};

export interface InPersonClinic {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  cityId: number;
  id: string;
  isActive: boolean;
}
export interface IProviderService {
  services: string[];
  clinics: InPersonClinic[];
}
