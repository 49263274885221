// import { connect } from 'react-redux';
// import { withTranslation } from 'react-i18next';
// import { RootState } from '../../../../shared/redux/reducer';

import RecurringSessionSchedulePopup from './RecurringSessionSchedulePopup';

export default RecurringSessionSchedulePopup;

// const mapStateToProps = (state: RootState) => ({
//   clientData: state.user.data,
// });

// export default connect(mapStateToProps)(
//   withTranslation()(RecurringSessionSchedulePopup),
// );
