import * as React from 'react';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '@constants/analytics';
import { FetchKeys } from '@constants/queries';
import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import Button from '@widgets/Button';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import useSessionConfirm from '../../hooks/useSessionConfirm';

interface MeetingConfirmButtonProps {
  meetingId: string;
  providerName: string;
}
export function MeetingConfirmButton({
  meetingId,
  providerName,
}: MeetingConfirmButtonProps) {
  const { track } = useTracking();
  const queryClient = useQueryClient();
  const { mutate: confirmSessionRequest } = useSessionConfirm({
    onSuccess: () => {
      queryClient.invalidateQueries([FetchKeys.providerHome]);
      toast(`You have successfully confirmed a session with ${providerName}`);
      track(EventNames.confirmSessionRequest, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.confirmSessionRequest,
        eventLabel: 'confirm_session_request',
        userType: Analytics.getUserType(),
      });
    },
    onError: (error) => {
      toast(error.message);
    },
  });
  return (
    <Button
      label="Confirm"
      variant="secondary"
      onClick={() => confirmSessionRequest(meetingId)}
    />
  );
}
