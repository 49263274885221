import * as React from 'react';
import Typography from '@widgets/Typography';
import { IRecommendationCard } from './types';
import './RecommendationCard.scss';

function RecommendationCard({
  icon,
  headerText,
  variant,
}: IRecommendationCard) {
  return (
    <article className={`card-parent ${variant}`}>
      <img src={icon} alt="card-icon" />
      <Typography size={12} weight="400">
        {headerText}
      </Typography>
    </article>
  );
}

export default RecommendationCard;
