import * as React from 'react';
import { ProviderAttributes } from '@constants/provider';
import { IProviderProfile } from '@customTypes/response/provider';
import Tags from '@widgets/Tags';
import Typography from '@widgets/Typography';

interface ProfileAttributesProps {
  providerProfile: IProviderProfile;
}

export function ProfileAttributes({ providerProfile }: ProfileAttributesProps) {
  const getProviderAttributes = (attribute: ProviderAttributes) =>
    (providerProfile?.attributes || []).filter(
      (attr: any) => attr.type === attribute,
    );

  const professionalBackgrounds = getProviderAttributes(
    ProviderAttributes.PROFESSIONAL_BACKGROUND,
  );

  const areaOfSpecialties = getProviderAttributes(
    ProviderAttributes.AREA_OF_SPECIALTY,
  );

  const myApproaches = getProviderAttributes(ProviderAttributes.MY_APPROACH);

  const getAvailableLanguages = () => {
    let availableLanguages: string[] = [];
    if (providerProfile.languages && providerProfile?.languages.length) {
      availableLanguages = providerProfile?.languages.map((l: any) => l.name);
    }

    return availableLanguages;
  };

  return (
    <>
      <div className="details-card">
        <Typography size={16} weight="500">
          Background
        </Typography>
        <ol>
          {professionalBackgrounds.map((attribute: any) => (
            <li key={attribute.id}>
              <Typography display="inline" size={12} weight="400">
                {attribute.name}
              </Typography>
            </li>
          ))}
        </ol>
      </div>
      <div className="details-card">
        <Typography size={16} weight="500">
          My approach
        </Typography>
        <ol>
          {myApproaches.map((attribute: any) => (
            <li key={attribute.id}>
              <Typography display="inline" size={12} weight="400">
                {attribute.name}
              </Typography>
            </li>
          ))}
        </ol>
      </div>
      <div className="details-card with-gap">
        <Typography size={16} weight="500">
          Languages I know
        </Typography>
        <Tags tags={getAvailableLanguages()} />
      </div>
      <div className="details-card with-gap">
        <Typography size={16} weight="500">
          Specialities
        </Typography>
        <Tags
          tags={areaOfSpecialties.map((speciality: any) => speciality.name)}
        />
      </div>
    </>
  );
}
