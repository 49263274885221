import * as React from 'react';
import { IProviderMeeting } from '@customTypes/response/meeting';
import { useQueryClient } from 'react-query';
import {
  EventNames,
  EventActions,
  EventCategories,
  BookSessionSources,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import KebabMenu from '@widgets/KebabMenu';
import { FetchKeys } from '@constants/queries';
import { IProviderProfile } from '@customTypes/response/provider';
import RescheduleSession from '@components/SessionSchedule/RescheduleSession';
import useTracking from '@hooks/useTracking';
import { useTranslation } from 'react-i18next';
import { checkWithin24hrs } from '../../utils/checkWithin24hrs';
import CancelConfirmedSession from '../../SessionCancelPopup/ConfirmedSession';
import { UpdateRoom } from '../../UpdateRoom/UpdateRoom';

interface ContextMenu extends IProviderMeeting {
  type: 'reschedule' | 'cancel' | 'update-room';
  isWithin24hrs: boolean;
}

interface MeetingKebabMenuProps {
  meetingData: IProviderMeeting;
  providerProfile: IProviderProfile;
}

export function MeetingKebabMenu({
  meetingData,
  providerProfile,
}: MeetingKebabMenuProps) {
  const [kebabContextMenu, setKebabContextMenu] =
    React.useState<ContextMenu | null>(null);

  const queryClient = useQueryClient();
  const { track } = useTracking();
  const { t } = useTranslation();

  const handleKebabMenuContext = (
    item: typeof kebabContextMenu,
    canRefetch = false,
  ) => {
    if (item && item.type && item.type !== 'update-room') {
      // temporary till data team creates the event
      track(EventNames[`${item.type}Session`], {
        eventAction: EventActions.click,
        eventCategory: EventCategories[`${item.type}Session`],
        eventLabel: Analytics.getUserType(),
        eventSource: BookSessionSources.providerProfile,
      });
    }
    setKebabContextMenu(item);
    if (canRefetch) {
      queryClient.invalidateQueries([FetchKeys.providerHome]);
    }
  };
  const isWithin24hrs = checkWithin24hrs(meetingData.scheduledStartTime * 1000);

  const handleTrackingBeforeClose = (
    canRefetch = false,
    shouldTrack = false,
  ) => {
    if (
      kebabContextMenu &&
      kebabContextMenu.type &&
      kebabContextMenu.type !== 'update-room' && // temporary till data team creates the event
      shouldTrack
    ) {
      track(EventNames.close, {
        eventAction: EventActions.click,
        eventCategory: EventCategories[`${kebabContextMenu.type}Session`],
        eventLabel: Analytics.getUserType(),
        eventSource: BookSessionSources.providerProfile,
      });
    }
    handleKebabMenuContext(null, canRefetch);
  };

  return (
    <>
      <KebabMenu
        iconPosition="horizontal"
        options={[
          {
            label: t('RESCHEDULE_SESSION'),
            value: 'reschedule',
            onClick: () =>
              handleKebabMenuContext({
                ...meetingData,
                type: 'reschedule',
                isWithin24hrs,
              }),
            disabled: isWithin24hrs,
          },
          {
            label: t('CANCEL_SESSION'),
            value: 'cancel',
            onClick: () =>
              handleKebabMenuContext({
                ...meetingData,
                type: 'cancel',
                isWithin24hrs,
              }),
          },
          ...(meetingData.room
            ? [
              {
                label: t('CHANGE_ROOM'),
                value: 'update-room',
                onClick: () =>
                  handleKebabMenuContext({
                    ...meetingData,
                    type: 'update-room',
                    isWithin24hrs,
                  }),
              },
            ]
            : []),
        ]}
      />
      {kebabContextMenu && kebabContextMenu.type === 'reschedule' && (
        <RescheduleSession
          onClose={handleTrackingBeforeClose}
          // source={BookSessionSources.providerProfile}
          role={meetingData.providerRole}
          providerData={providerProfile}
          meetingData={kebabContextMenu}
        />
      )}
      {kebabContextMenu && kebabContextMenu.type === 'cancel' && (
        <CancelConfirmedSession
          onClose={handleTrackingBeforeClose}
          // source={BookSessionSources.providerProfile}
          meetingData={kebabContextMenu}
          isWithin24hrs={kebabContextMenu.isWithin24hrs}
        />
      )}
      {kebabContextMenu && kebabContextMenu.type === 'update-room' && (
        <UpdateRoom
          onClose={handleTrackingBeforeClose}
          // source={BookSessionSources.providerProfile}
          meetingData={kebabContextMenu}
        />
      )}
    </>
  );
}
