import { useFetch } from '@hooks/useFetch';
import { getProviderOpenSlotsV2 } from '@services/provider';

function useRecurringProviderSlotsV2<T>({
  providerId,
  role,
  locationId,
  count,
  userId,
  enabled,
}: {
  providerId: string;
  role: string;
  locationId: string | null;
  count?: string;
  userId?: string;
  enabled?: boolean;
}) {
  return useFetch<T>(
    ['provider', 'slots', providerId, locationId],
    () =>
      getProviderOpenSlotsV2({ providerId, role, locationId, userId, count }),
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
}

export default useRecurringProviderSlotsV2;
