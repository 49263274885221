import { IUnmatchRequest } from '@customTypes/request/provider';
import { unmatchProvider } from '@services/user';
import { useMutation } from 'react-query';

function useUnmatch(
  unmatchData: IUnmatchRequest,
  {
    onSuccess,
    onError,
  }: { onSuccess: () => void; onError: (error: Error) => void },
) {
  return useMutation(() => unmatchProvider(unmatchData), {
    onSuccess,
    onError,
  });
}

export default useUnmatch;
