import { FetchKeys } from '@constants/queries';
import { EmployeeDetails } from '@customTypes/response/IUser';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getEmployeeDetails } from '@services/user';

function useEmployeeDetails(userId: string, { onSuccess }: PromiseCallbacks) {
  return useFetch<EmployeeDetails>(
    [FetchKeys.employeeDetails, userId],
    () => getEmployeeDetails(userId),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    },
  );
}

export default useEmployeeDetails;
