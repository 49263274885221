import * as React from 'react';
import {
  IProviderProfile,
  MeetingStatus,
} from '@customTypes/response/provider';
import { ApiDateFormat } from '@utilities/common/Date';
import { getTruncatedMeetingId } from '@utilities/common/Display';
import { getImageUri, openNewTab } from '@utilities/common/Path';
import Typography from '@widgets/Typography';
import UnorderedList from '@widgets/UnorderedList';
import moment from 'moment';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import Loader from '@widgets/Loader';
import { IProviderMeeting } from '@customTypes/response/meeting';
import useAuth from '@hooks/useAuth';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '@constants/analytics';
import { VideoRoomSDKs } from '@constants/App';
import Analytics from '@services/Analytics';
import toast from 'react-hot-toast';
import useTracking from '@hooks/useTracking';
import { ActionCTAs } from './ActionCTAs/ActionCTAs';
import locationIcon from '../assets/location-point.svg';
import calenderIcon from '../assets/calender.svg';
import clockIcon from '../assets/clock.svg';
import useMeeting from '../../Room/hooks/useMeeting';
import { getDailyCoUrlFromRoomId } from '../../Room/utils';

interface MeetingsListProps {
  providerProfile: IProviderProfile;
  currentMeetings: IProviderMeeting[] | undefined;
}

export function MeetingsList({
  currentMeetings,
  providerProfile,
}: MeetingsListProps) {
  const { user } = useAuth();

  const { track } = useTracking();

  const { refetch: fetchMeetingJoinData, isLoading: isJoiningMeeting } =
    useMeeting(currentMeetings?.[0]?.id || '', VideoRoomSDKs.dailyCo, false, {
      onSuccess: (meetingJoinData) => {
        const { meeting } = meetingJoinData || {};
        const { locationRef } = meeting || {};
        openNewTab(`room/?roomUrl=${getDailyCoUrlFromRoomId(locationRef)}`);
        track(EventNames.joinSession, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.joinSession,
          eventLabel: 'join_session',
          userType: Analytics.getUserType(),
        });
      },
      onError: (error: Error) => {
        toast(error.message);
      },
    });

  const {
    currentUserData: { currentUserConfig: variables, isLoading: isUserLoading },
  } = useCurrentUserContext();

  if (isUserLoading) {
    return <Loader useFullPage={false} />;
  }
  if (isJoiningMeeting) {
    return <Loader />;
  }

  return currentMeetings?.length ? (
    <div className="session-details">
      <Typography size={16} weight="500">
        Upcoming session
      </Typography>
      {currentMeetings.map((meeting, index) => {
        const isUpcomingMeeting = index === 0;
        const {
          status,
          id,
          displayDate,
          displayTime,
          displayTimeEnd,
          meetingTimeZone,
          room,
        } = meeting;

        const detailListItems = [
          {
            label: moment(displayDate, ApiDateFormat).format('MMM DD, YYYY'),
            icon: calenderIcon,
          },
          {
            label: `${displayTime} - ${displayTimeEnd} ${meetingTimeZone}`,
            icon: clockIcon,
          },
        ];

        if (room) {
          detailListItems.push({
            label: room.name,
            icon: locationIcon,
          });
        }
        return (
          <div className="session-container" key={id}>
            <div className="left-section">
              <Typography size={16} weight="500">
                {status === MeetingStatus.userActionPending
                  ? 'Session request'
                  : getTruncatedMeetingId(id)}
              </Typography>
              <UnorderedList items={detailListItems} />
              <div className="row-item">
                <img
                  className="profile"
                  src={getImageUri(providerProfile.profileImage || '')}
                  alt={providerProfile.name}
                />
                <Typography size={10} weight="500">
                  {providerProfile.name}
                </Typography>
              </div>
              {variables?.isCare && user.isEmployee && isUpcomingMeeting && (
                <Typography size={12} weight="500" color="secondary">
                  Your clinician might be wrapping up a session prior to yours.
                  If they have yet to join the session within 5 minutes, kindly
                  contact tiktok@intellect.co immediately.
                </Typography>
              )}
            </div>
            <ActionCTAs
              meetingData={meeting}
              providerProfile={providerProfile}
              isUpcomingMeeting={isUpcomingMeeting}
              fetchMeetingJoinData={fetchMeetingJoinData}
            />
          </div>
        );
      })}
    </div>
  ) : null;
}
