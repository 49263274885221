/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import './Login.scss';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEmployeeDataFromSearchParam } from '@utilities/common/Responder';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import Button from '../../widgets/Button';
import TextBox from '../../widgets/TextBox';
import Typography from '../../widgets/Typography';
import angleLeftIcon from '../../../assets/angle-left.png';
import angleRightIcon from '../../../assets/angle-right.png';
import quoteIcon from '../../../assets/quote.png';
import Loader from '../../widgets/Loader';
import useAuth from '../../../utilities/hooks/useAuth';
import useResponderLogin from './hooks/useResponderLogin';

interface ILoginFormData {
  inputs: {
    email: string;
    password: string;
  };
  loginMessage: {
    isError: boolean;
    message: string;
  };
}

export default function LoginScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useTracking();

  const [searchParams] = useSearchParams();

  const { isLoggedIn, user, invalidateUser, overrideUserProperties } =
    useAuth();
  const { setValue, control, trigger, watch } = useForm<ILoginFormData>({
    defaultValues: {
      inputs: {
        email: '',
        password: '',
      },
      loginMessage: { isError: false, message: '' },
    },
    mode: 'onTouched',
  });

  const userData = searchParams.get('user');

  const { mutate: login, isLoading } = useResponderLogin(
    watch('inputs.email'),
    watch('inputs.password'),
    userData,
    {
      onSuccess: () => {
        track(EventNames.Login, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.login,
          eventLabel: 'login_succesful',
          featureVersion: 'v1',
          eventValue: watch('inputs.email'),
          userType: Analytics.getUserType(),
        });
        navigate('/notebook/');
      },
      onError: (error) => {
        track(EventNames.Login, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.login,
          eventLabel: 'login_failed',
          featureVersion: 'v1',
          userType: '-',
          eventValue: watch('inputs.email'),
        });
        setValue('loginMessage', { isError: true, message: error.message });
      },
    },
  );

  React.useEffect(() => {
    if (isLoggedIn && !user.isEmployee && userData) {
      const decodedUserData = getEmployeeDataFromSearchParam(userData);
      if (decodedUserData && decodedUserData.email && decodedUserData.id) {
        const { email, id, sessionId, phoneNumber } = decodedUserData;
        overrideUserProperties({
          id,
          email,
          phoneNumber,
          sessionId,
        });
        navigate('/notebook/');
      } else if (isLoggedIn && user.isEmployee) {
        invalidateUser();
      } else {
        toast(
          'Unable to verify the user data, please try again from the responder platform.',
        );
      }
    }
  }, []);

  const onSignInCLick = () => {
    login();
  };

  // TODO convert into form to make it implicit
  const onFormKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      onSignInCLick();
    }
  };

  return (
    <div className="login-container">
      {isLoading && <Loader useFullPage />}
      <div className="action-container">
        <div className="logo-container">
          <Typography weight="700" size={15} color="primary">
            {t('intellect')}
          </Typography>
        </div>
        <div className="login-form">
          <div className="login-header">
            <Typography color="primary" weight="600" size={22}>
              {t('letsSignIn')}
            </Typography>
          </div>
          <div className="login-header">
            <Typography color="primary" weight="400" size={11}>
              {t('welcomeBack')}
            </Typography>
          </div>
          <div className="form-container" onKeyDown={onFormKeyDown} role="form">
            <Controller
              name="inputs.email"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextBox
                  {...field}
                  placeholder={t('emailAddress')}
                  variant="box"
                  onChange={(e) => {
                    onChange(e);
                    trigger();
                  }}
                />
              )}
            />
            <Controller
              name="inputs.password"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextBox
                  {...field}
                  placeholder="Password"
                  type="password"
                  variant="box"
                  onChange={(e) => {
                    onChange(e);
                    trigger();
                  }}
                />
              )}
            />
            {watch('loginMessage.message') && (
              <Typography
                size={12}
                weight="400"
                color={watch('loginMessage.isError') ? 'error' : 'primary'}
              >
                {watch('loginMessage.message')}
              </Typography>
            )}
            <Button label="Sign in" variant="primary" onClick={onSignInCLick} />
          </div>
        </div>
      </div>
      <div className="intro-container">
        <motion.div
          className="circle-fade"
          initial={{ right: '40%' }}
          animate={{ right: '35%' }}
          transition={{
            duration: '3',
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        <div className="content-container">
          <motion.img
            src={quoteIcon}
            alt="quote"
            className="quote-icon"
            initial={{ translateY: '-10px' }}
            animate={{ translateY: '0px' }}
            transition={{
              duration: '1',
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <div className="intro">
            <Typography color="light" weight="400" size={15}>
              {t('intellectDescription')}
            </Typography>
          </div>
          <img src={angleRightIcon} className="right-angle" alt="right angle" />
          <img src={angleLeftIcon} alt="left angle" />
        </div>
        <div className="rect-arrow" />
      </div>
    </div>
  );
}
