import React from 'react';
import moment from 'moment';
import Typography from '@widgets/Typography';
import { displayDateFormat } from '@utilities/common/Display';
import './ClientDetailsCard.scss';
import { EmployeeDetails } from '@customTypes/response/IUser';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';

interface IClientDataprops {
  // clientData: {
  //   name: string;
  // };
  providerRole: string;
  employeeDetails: EmployeeDetails | undefined;
}

function ClientDetailsCard({
  // clientData,
  providerRole,
  employeeDetails,
}: IClientDataprops) {
  const {
    currentUserData: { userData: clientData },
  } = useCurrentUserContext();
  const [creditStatus, setCreditStatus] = React.useState<string>('');
  // Component Life Cycle
  React.useEffect(() => {
    if (employeeDetails) {
      const creditsList = employeeDetails?.credits || {};
      const credits = creditsList[providerRole];
      const availableCredits = credits?.credit;
      if (credits?.tier === '1') {
        setCreditStatus('Unlimited');
      } else if (credits?.credit === 0 && credits?.canBook) {
        setCreditStatus('Credit Pool');
      } else if (credits?.credit === 1 && credits?.canBook) {
        setCreditStatus(`${availableCredits} credit left`);
      } else if (credits?.credit > 1 && credits?.canBook) {
        setCreditStatus(`${availableCredits} credits left`);
      } else if (credits?.credit === 0 && !credits?.canBook) {
        setCreditStatus('No credits left');
      } else {
        setCreditStatus('No credits left');
      }
    }
  }, [employeeDetails]);

  return (
    <div className="client-card-container">
      <div className="client-details-container">
        <Typography size={24} weight="500" color="secondary" textAlign="left">
          {clientData?.name}
        </Typography>
        <Typography size={12} weight="400" color="secondary" textAlign="left">
          {employeeDetails?.recentMeeting && (
            <span>
              {`Last session on ${moment(employeeDetails?.recentMeeting).format(
                displayDateFormat,
              )}`}
              &nbsp;&nbsp;&nbsp;
            </span>
          )}
          {`Expiring on ${moment(
            employeeDetails?.subscription?.[0]?.expiresOn,
          ).format(displayDateFormat)}`}
        </Typography>
      </div>
      <div className="credits-container">
        <Typography size={12} weight="500" withColor="#ffffff" textAlign="left">
          {creditStatus}
        </Typography>
      </div>
    </div>
  );
}

export default ClientDetailsCard;
