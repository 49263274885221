import { unmatchReasonsData } from '@constants/provider';
import useAuth from '@hooks/useAuth';
import toast from 'react-hot-toast';

import Button from '@widgets/Button';
import Loader from '@widgets/Loader';
import Popup from '@widgets/Popup';
import RadioGroup from '@widgets/RadioGroup';
import TextArea from '@widgets/TextArea';
import Typography from '@widgets/Typography';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useTracking from '@hooks/useTracking';
import useLayout from '@hooks/useLayout';
import useUnmatch from './hooks/useUnmatch';
import './UnmatchPopup.scss';

interface UnmatchPopupProps {
  providerId: string;
  onClose: (unmatched: boolean) => void;
}

function UnmatchPopup({ providerId, onClose }: UnmatchPopupProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { track } = useTracking();
  const { reloadChat } = useLayout();
  const [selectedReason, setSelectedReason] = React.useState<string>('');
  const [showNote, setShowNote] = React.useState<boolean>(false);
  const [enteredNote, setEnteredNote] = React.useState<string>('');

  const { id: userId } = user;

  const { isLoading: isBeingUnmatched, mutate: performUnmatch } = useUnmatch(
    {
      providerId,
      userId,
      reason: selectedReason,
      feedback: enteredNote,
    },
    {
      onSuccess: () => {
        onClose(true);
        navigate('/');
        reloadChat?.();
        toast('Provider unmatched.');
        track(EventNames.unmatchConfirm, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.unmatchConfirm,
          eventLabel: 'unmatch_confirm',
          userType: Analytics.getUserType(),
        });
      },
      onError: (error: Error) => {
        toast(error.message);
      },
    },
  );

  const unmatchReasons = React.useMemo(
    () =>
      unmatchReasonsData.map(({ id, label }) => ({
        label,
        value: id,
      })),
    [],
  );

  const onContinueClick = () => {
    if (!showNote) {
      setShowNote(true);
    } else {
      performUnmatch();
    }
  };

  const shouldDisableCta = () => {
    if (!selectedReason) {
      return true;
    }
    if (showNote && !enteredNote) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    track(EventNames.unmatchRequest, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.unmatchRequest,
      eventLabel: 'unmatch_request',
      userType: Analytics.getUserType(),
    });
  }, []);

  return (
    <Popup onClose={() => onClose(false)}>
      <div className="unmatch-container">
        {isBeingUnmatched && <Loader useFullPage />}
        <div className="unmatch-header">
          <Typography size={24} weight="500">
            We’re sorry to see you go.
          </Typography>
          <Typography size={16} weight="400" color="secondary">
            Let us know what went wrong, where can we improve
          </Typography>
        </div>
        {!showNote ? (
          <div className="reasons">
            <RadioGroup
              variant="box"
              name="unmatch-reasons"
              values={unmatchReasons}
              selectedValue={selectedReason}
              onChange={(value) => setSelectedReason(value)}
            />
          </div>
        ) : (
          <div className="notes-section">
            <TextArea
              placeholder="Let us know what went wrong..."
              value={enteredNote}
              onChange={(note) => setEnteredNote(note)}
            />
          </div>
        )}
        <div className="unmatch-action">
          <Button
            width="90%"
            variant="primary"
            label={showNote ? 'Unmatch' : 'Continue'}
            onClick={() => onContinueClick()}
            disabled={shouldDisableCta()}
          />
        </div>
      </div>
    </Popup>
  );
}

export default UnmatchPopup;
