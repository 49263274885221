import * as React from 'react';
import './FlatSelect.scss';
import Typography from '@widgets/Typography';
import { motion } from 'framer-motion';

interface FlatSelectOption {
  value: string;
  label: string;
  indicator?: string;
  disabled?: boolean;
}

interface FlatSelectProps {
  selectedOption: string | null;
  options: FlatSelectOption[];
  id: string;
  onChange: (value: string) => void;
}

function FlatSelect({ selectedOption, options, onChange, id }: FlatSelectProps) {

  return (
    <div className="flat-select-wrapper">
      {options.map((option, index) => {
        const { value, label, indicator, disabled } = option;
        const isSelected = value === selectedOption;

        return (
          <motion.div
            role="button"
            tabIndex={index}
            onClick={() => !disabled && onChange(option.value)}
            className={`select-item ${indicator ? 'indicator' : ''} ${
              disabled ? 'disabled' : ''
            }`}
            data-indicator={indicator}
            key={`${id}-${value}`}
          >
            {isSelected && (
              <motion.div className="selected" />
            )}
            <Typography
              size={14}
              weight="500"
              color={isSelected ? 'primary' : 'secondary'}
            >
              {label}
            </Typography>
          </motion.div>
        );
      })}
    </div>
  );
}

export default FlatSelect;
