export const FrequencyBasedText: {
  [key: string]: {
    RecommendationCardText: string;
    RepeatCardText: string;
  };
} = {
  '2': {
    RecommendationCardText:
      'Over 70% of Intellect users in recurring sessions improved their self-confidence and work performance.',
    RepeatCardText: 'Repeats once a week ',
  },
  '3': {
    RecommendationCardText:
      "You're more likely to achieve your goals by attending regular weekly sessions",
    RepeatCardText: 'Repeats once every two weeks ',
  },
  '1': {
    RecommendationCardText:
      "You're more likely to achieve your goals by attending at least 4-6 weekly sessions.",
    RepeatCardText: 'Single Session',
  },
};
