import * as React from 'react';
import useFilterContext from '@components/FilterSection/context/useFilterContext';
import { ProviderRole } from '@customTypes/response/IChat';
import useAuth from '@hooks/useAuth';
import { ProfileDetails } from '../../MeetingProfile/ProfileDetails/ProfileDetails';
import { MeetingsList } from '../../MeetingProfile/MeetingsList/MeetingsList';
import { ProfileAttributes } from '../../MeetingProfile/ProfileAttributes/ProfileAttributes';
import FilterSection from '../FilterSection/FilterSection';

import useProviderProfile from '../../MeetingProfile/hooks/useProviderProfile';
import './ProviderProfile.scss';


export function CareNavProviderProfile({ providerRole, providerProfile, currentMeetings }: {
  providerRole: ProviderRole;
  providerProfile: any,
  currentMeetings: any[],
}) {
  const { headerRef } = useFilterContext();
  const getHeight = React.useMemo(
    () => window.innerWidth > 800 ? `calc(100vh - ${(headerRef?.current?.clientHeight || 0) + 90}px)` : `calc(96vh - ${(headerRef?.current?.clientHeight || 0)}px)`,
    [headerRef, window.innerWidth],
  );

  const { user } = useAuth();

  const { data: providerAvailability } = useProviderProfile({
    providerId: Number(providerProfile.providerId),
    role: providerRole,
    userId: user.id,
  });

  return <div>
    <FilterSection isProfile />
    <div className="meeting-profile" style={{ height: getHeight }}>
      <ProfileDetails
        providerProfile={{ ...providerProfile, ...providerAvailability }}
        currentMeetings={currentMeetings}
        isMatchedProvider={!!providerProfile}
      />

      <MeetingsList
        providerProfile={providerProfile}
        currentMeetings={currentMeetings}
      />

      <ProfileAttributes providerProfile={providerProfile} />
    </div>
  </div>;
}
