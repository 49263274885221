import { PromiseCallbacks, useMutation } from '@hooks/useFetch';
import { confirmSessionRequest } from '@services/provider';

function useSessionConfirm({ onSuccess, onError }: PromiseCallbacks) {
  return useMutation((meetingId: string) => confirmSessionRequest(meetingId), {
    onSuccess,
    onError,
  });
}

export default useSessionConfirm;
