import { useFetch } from '@hooks/useFetch';
import { getProviderProfile } from '@services/provider';
import { FetchKeys } from '@constants/queries';
import { IProviderProfile } from '@customTypes/response/provider';

function useProviderProfile({
  providerId,
  role,
  userId,
}: {
  providerId: number;
  role: string;
  userId: string;
}) {
  return useFetch<IProviderProfile>(
    [FetchKeys.providerDetails, providerId, role, userId],
    () =>
      getProviderProfile({ providerId: providerId.toString(), role, userId }),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export default useProviderProfile;
