import { updateRoom } from '@services/carenav';

import { useMutation } from 'react-query';

function useUpdateRoom({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) {
  return useMutation(
    (params: { meetingId: string; roomId: string; newRoomId: string }) =>
      updateRoom(params),
    {
      onSuccess,
      onError,
    },
  );
}

export default useUpdateRoom;
