import * as React from 'react';
import Typography from '@widgets/Typography';
import { CancellationPolicyFAQ1, CancellationPolicyFAQ2 } from '../constants';
import './CancellationPolicies.scss';

function CancellationPolicies() {
  return (
    <article className="policy-container">
      <section>
        <Typography
          size={16}
          weight="600"
          withColor="#0A0C0E"
          textAlign="center"
        >
          Getting ready for your call
        </Typography>
        <Typography size={12} weight="400" color="swamp" textAlign="center">
          Here are some helpful answers to commonly asked questions
        </Typography>
      </section>
      <section className="policies-desc-wrapper">
        <section className="left-section">
          {CancellationPolicyFAQ1.map((item) => (
            <ul key={item.id}>
              <li>
                <Typography size={14} weight="500" withColor="#000">
                  {item.query}
                </Typography>
              </li>
              <Typography size={14} weight="400" withColor="#484F54">
                {item.response}
              </Typography>
            </ul>
          ))}
        </section>
        <section className="right-section">
          {CancellationPolicyFAQ2.map((item) => (
            <ul key={item.id}>
              <li>
                <Typography size={14} weight="500" withColor="#000">
                  {item.query}
                </Typography>
              </li>
              <Typography size={14} weight="400" withColor="#484F54">
                {item.response}
              </Typography>
            </ul>
          ))}
        </section>
      </section>
    </article>
  );
}

export default CancellationPolicies;
