import Typography from '@widgets/Typography';
import * as React from 'react';

interface ProfileBioProps {
  bio: string;
}
export function ProfileBio({ bio }: ProfileBioProps) {
  const [showMoreBio, setShowMoreBio] = React.useState(false);
  return (
    <div className="individual-details">
      <div className="detail-item">
        <Typography size={14} weight="500">
          About me
        </Typography>
        {window.innerWidth <= 1000 ? (
          <div>
            <Typography size={14} weight="400" color="secondary">
              {bio?.slice(0, showMoreBio ? bio.length : 140)}
              {!showMoreBio ? '...' : ''}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div onClick={() => setShowMoreBio(!showMoreBio)}>
                <Typography size={12} weight="600">
                  {showMoreBio ? 'Show less' : 'Show more'}
                </Typography>
              </div>
            </Typography>
          </div>
        ) : (
          <Typography size={14} weight="400" color="secondary">
            {bio}
          </Typography>
        )}
      </div>
    </div>
  );
}
