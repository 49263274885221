import * as React from 'react';
import './RecurringSessionSchedulePopup.scss';

import {
  AvailableSessionDurations,
  IProviderOpenSlotsFullWithFrequency,
  IProviderProfile,
  IProviderService,
} from '@customTypes/response/provider';
import { VideoRoomSDKs } from '@constants/App';
import Analytics from '@services/Analytics';
import useTracking from '@hooks/useTracking';
import Popup from '@widgets/Popup';
import useAuth from '@hooks/useAuth';
import { ApiDateFormat, getTimeZoneAbbreviation } from '@utilities/common/Date';
import Button from '@widgets/Button';
import { clinicalSessionDurations, coachingSessionDurations, ProviderRoles, ProviderTags } from '@constants/provider';
import FlatSelect from '@widgets/FlatSelect';
import { RecurringSessions } from '@constants/user';
import { BookSessionSources, EventActions, EventCategories, EventNames } from '@constants/analytics';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { EmployeeDetails } from '@customTypes/response/IUser';
import { checkUserBookingAbility, getBookingSource } from '@utilities/common/user';
import { useTranslation } from 'react-i18next';
import Loader from '@widgets/Loader';
import Typography from '@widgets/Typography';
import SelectList from '@widgets/SelectList';
import DatePicker from '@widgets/DatePicker';
import useLayout from '@hooks/useLayout';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import SelectBox from '@widgets/SelectBox';
import TextBox from '@widgets/TextBox';
import useFilterContext from '@components/FilterSection/context/useFilterContext';
import { ITimezoneByCountry, TimezoneByCountry } from '@constants/timezones';
import { getCountryDataFromCountryCode } from '@utilities/common/Timezone';
import useBooking from '../hooks/useBooking';
import useProviderMatching from '../hooks/useProviderMatching';
import useEmployeeDetails from '../hooks/useEmployeeDetails';
import { RecurringSessionSuccessData } from '../types';
import useRecurringProviderSlotsV2 from '../hooks/useRecurringProviderSlotsV2';
import ClientDetailsCard from '../ClientDetailsCard';
import useProviderLeaveDays from '../hooks/useProviderLeaveDays';
import useProviderServices from '../hooks/useProviderServices';

interface IRecurringSessionSchedulePopup {
  closeHandler: (
    data: Omit<RecurringSessionSuccessData, 'showSuccessModal'>,
  ) => void;
  role: string;
  providerData: IProviderProfile;
  source: (typeof BookSessionSources)[keyof typeof BookSessionSources];
  isProviderMatched: boolean;
  // clientData: {
  //   name: string;
  // };
}

function RecurringSessionSchedulePopup({
  closeHandler,
  role,
  providerData,
  source,
  isProviderMatched,
}: // clientData,
IRecurringSessionSchedulePopup) {
  const { user } = useAuth();
  const { track } = useTracking();
  const { t } = useTranslation();
  const {
    currentUserData: { userData: clientData },
  } = useCurrentUserContext();
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [selectedSessionDuration, setSelectedSessionDuration] =
    React.useState<AvailableSessionDurations | null>(null);
  const [selectedSlot, setSelectedSlot] = React.useState<string>('');
  const [selectedFrequency, setSelectedFrequency] = React.useState<string>('');
  const sessionLoadEventFired = React.useRef<boolean>(false);
  const [locationId, setLocationId] = React.useState<string>('');
  const [locationRef, setLocationRef] = React.useState<string>('');
  const {
    filterData,
    updateFilterData,
  } = useFilterContext();

  const { providerId } = providerData || {};
  const { data: employeeData, isLoading: isEmployeeDataLoading } =
    useEmployeeDetails(user.id, {
      onSuccess: (data: EmployeeDetails) => {
        const { credits } = data || {};

        const userBookingAbility = checkUserBookingAbility(credits?.[role]);
        if (
          userBookingAbility.canBookSingleSession &&
          !userBookingAbility.canBookRecurringSession
        ) {
          // overriding default value
          setSelectedFrequency(
            RecurringSessions.availableRecurringSessionOptions.ONE_TIME,
          );
        } else if (
          !userBookingAbility.canBookRecurringSession &&
          !userBookingAbility.canBookSingleSession
        ) {
          // don't have enough credits to book any session.
          setSelectedFrequency('');
        } else {
          // can book any frequencies
        }
      },
    });

  const { isLoading: isProviderServicesLoading, data: providerServices } =
    useProviderServices(providerId.toString(), role, {
      onSuccess: (data: IProviderService) => {
        if (data.clinics.length === 1) {
          setLocationId(data.clinics[0].id);
        }
      },
    });

  React.useEffect(() => {
    if (role === ProviderRoles.INTELLECTCLINICOFFSITE && user.isCareNavigator) {
      setSelectedSessionDuration('3600');
      setSelectedFrequency('1');
    }
  }, []);

  const { isLoading: isLeaveDaysLoading, data: providerLeaveDays } =
    useProviderLeaveDays(providerId.toString());

  const { credits, meetingAllowed } = employeeData || {};

  const getRecurringBookingCreditsCount = () => {
    if (
      selectedFrequency ===
      RecurringSessions.availableRecurringSessionOptions.ONE_TIME
    ) {
      return RecurringSessions.constants.ONE_TIME_SESSION_CREDIT_COUNT;
    }

    return meetingAllowed?.[role]?.count || 0;
  };

  const { isLoading: isSlotsLoading, data: providerOpenSlots } =
    useRecurringProviderSlotsV2<IProviderOpenSlotsFullWithFrequency>({
      providerId: providerId.toString(),
      role,
      locationId,
      count: locationId
        ? undefined
        : getRecurringBookingCreditsCount().toString(),
      userId: user.id,
      enabled: !!employeeData,
    });

  const getProviderTags = () => {
    if (user.isEmployee) return ProviderTags.selfScheduled;
    if (user.isResponder || user.isCareNavigator)
      return ProviderTags.helplineReferral;

    return '';
  };

  const getSummaryLabels = () => {
    let summaryLabels = {
      frequency: '',
      sessionsCount: 0,
    };
    const frequencyLabels = {
      [RecurringSessions.availableRecurringSessionOptions.ONE_TIME]:
        'Session Selected on',
      [RecurringSessions.availableRecurringSessionOptions.WEEKLY]:
        'Repeats once a week on',
      [RecurringSessions.availableRecurringSessionOptions.BIWEEKLY]:
        'Repeats once in every 2 weeks on',
    };

    if (selectedFrequency && selectedDate && selectedSlot) {
      summaryLabels = {
        ...summaryLabels,
        frequency: frequencyLabels[selectedFrequency],
        sessionsCount: getRecurringBookingCreditsCount(),
      };
    }

    return summaryLabels;
  };

  const summaryLabels = getSummaryLabels();

  const getAvailableSlots = () => {
    let slots: { label: string; value: string }[] = [];
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[selectedFrequency] &&
      providerOpenSlots[selectedFrequency][selectedSessionDuration]
    ) {
      const availableSlotsForSelectedFrequency =
        providerOpenSlots[selectedFrequency];
      const availableSlots =
        availableSlotsForSelectedFrequency[selectedSessionDuration];

      slots = availableSlots
        .filter((slot) => slot.displayDate === selectedDate)
        .map(({ displayTime, startTimeUtc }) => ({
          label: displayTime,
          value: startTimeUtc.toString(),
        }));
    }

    return slots;
  };

  const slots = React.useMemo(
    () => getAvailableSlots(),
    [selectedSessionDuration, selectedDate, locationId, selectedFrequency],
  );

  const { isLoading: isMeetingBeingBooked, mutate: bookMeeting } = useBooking(
    user.id,
    {
      role,
      sessionId: user.sessionId,
      scheduledStartTime: selectedSlot,
      duration: selectedSessionDuration as AvailableSessionDurations,
      type:
        role === ProviderRoles.INTELLECTCLINICOFFSITE
          ? VideoRoomSDKs.intellectClinicOffsite
          : VideoRoomSDKs.dailyCo,
      interval: selectedFrequency,
      count: getRecurringBookingCreditsCount().toString(),
      source: getBookingSource(user),
      userTimeZone: !user.isEmployee ? filterData.timezone : undefined,
      // eslint-disable-next-line no-nested-ternary
      ...(role === ProviderRoles.INTELLECTCLINICOFFSITE
        ? user.isCareNavigator
          ? { locationId }
          : { locationRef }
        : null),
    },
    {
      onSuccess: (data) => {
        const selectedTime = slots.find((s) => s.value === selectedSlot)?.label;
        const selectedTimeMoment = moment(selectedTime, 'hh:mmA');
        const nextSessionOn = moment(selectedDate, ApiDateFormat)
          .set({
            hour: selectedTimeMoment.hour(),
            minute: selectedTimeMoment.minute(),
          })
          .toISOString();

        track(EventNames.confirmSession, {
          source,
          eventAction: EventActions.click,
          eventCategory: selectedFrequency,
          eventLabel: (meetingAllowed?.[role]?.count || 0).toString(),
          userType: Analytics.getUserType(),
          eventSource: 'virtual',
        });

        closeHandler({
          nextSessionOn,
          hasBooked: true,
          sessionsCount: getRecurringBookingCreditsCount().toString(), // credits or something else
          frequencyLabel: summaryLabels.frequency,
          location:
            role === ProviderRoles.INTELLECTCLINICOFFSITE ? data.room : null,
        });
      },

      onError: () => {
        toast(
          'Oops! Something has went wrong while booking a session, please try again!',
        );
      },
    },
  );

  const { reloadChat } = useLayout();

  const { isLoading: isProviderBeingMatched, mutate: matchProvider } =
    useProviderMatching(
      {
        providerId,
        role, // overriding role as 1 since its specific for TikTok
        userId: user.id,
        tags: getProviderTags(),
        friendlyName: clientData?.name,
        userTimeZone: !user.isEmployee ? filterData.timezone : undefined,
      },
      {
        onSuccess: () => {
          bookMeeting();
          reloadChat?.();
        },
        onError: () => {
          toast(
            'Oops! Something went wrong while trying to match with the provider, please try again!',
          );
        },
      },
    );

  const onSchedule = () => {
    if (!isProviderMatched) {
      matchProvider();
    } else {
      bookMeeting();
    }
  };

  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      selectedFrequency &&
      providerOpenSlots[selectedFrequency] &&
      providerOpenSlots[selectedFrequency][selectedSessionDuration]
    ) {
      return providerOpenSlots[selectedFrequency][selectedSessionDuration].map(
        (slot) => slot.displayDate,
      );
    }

    return [];
  };

  const getRecurringSessionOptions = () => {
    const userBookingAbility = checkUserBookingAbility(credits?.[role]);

    return [
      {
        label: 'Weekly',
        value: '2',
        indicator:
          role === ProviderRoles.INTELLECTCLINICOFFSITE && user.isCareNavigator
            ? ''
            : 'Recommended',
        disabled:
          !userBookingAbility.canBookRecurringSession ||
          user.isCareNavigator ||
          _.isEmpty(
            providerOpenSlots?.[
              RecurringSessions.availableRecurringSessionOptions.WEEKLY
            ],
          ),
      },
      {
        label: 'Biweekly',
        value: '3',
        disabled:
          !userBookingAbility.canBookRecurringSession ||
          user.isCareNavigator ||
          _.isEmpty(
            providerOpenSlots?.[
              RecurringSessions.availableRecurringSessionOptions.BIWEEKLY
            ],
          ),
      },
      {
        label: 'One-time',
        value: '1',
        disabled:
          !userBookingAbility.canBookSingleSession ||
          _.isEmpty(
            providerOpenSlots?.[
              RecurringSessions.availableRecurringSessionOptions.ONE_TIME
            ],
          ),
      },
    ];
  };

  const fireSessionLoadAnalyticsEvent = () => {
    const bookingOptions = getRecurringSessionOptions();
    const availableOptions = bookingOptions
      .filter((option) => !option.disabled)
      .map((op) => op.value);

    track(EventNames.sessionDetails, {
      source,
      eventAction: EventActions.load,
      eventCategory: availableOptions.join(','),
      eventLabel: (employeeData?.meetingAllowed?.[role]?.count || 0).toString(),
      userType: Analytics.getUserType(),
    });
  };

  if (providerOpenSlots && employeeData && !sessionLoadEventFired.current) {
    fireSessionLoadAnalyticsEvent();
    sessionLoadEventFired.current = true;
  }

  React.useEffect(() => {
    track(EventNames.bookSession, {
      source,
      eventAction: EventActions.click,
      eventCategory: EventCategories.bookSession,
      eventLabel: 'book_session',
      userType: Analytics.getUserType(),
      eventSource: 'virtual',
    });
  }, []);

  return (
    <Popup withBackdrop onClose={() => closeHandler({ hasBooked: false })}>
      <div className="add-leave-popup">
        {(isSlotsLoading ||
          isEmployeeDataLoading ||
          isProviderBeingMatched ||
          isMeetingBeingBooked ||
          isLeaveDaysLoading ||
          isProviderServicesLoading) && <Loader useFullPage />}
        <div className="popup-header">
          <Typography size={20} weight="500">
            Book a session
          </Typography>
          {providerData.provider.leave.onLeave ? (
            <div className="on-leave-banner">
              {t('BANNER_TEXT_OOO', {
                date: providerData.provider.leave.to
                  ? moment.unix(providerData.provider.leave.to).format('DD MMM')
                  : '',
              })}
            </div>
          ) : null}
        </div>
        <div className="schedule-form">
          <div className="form-heading">
            <Typography size={14} weight="500">
              Client details
            </Typography>
          </div>
          <ClientDetailsCard
            providerRole={role}
            employeeDetails={employeeData}
          />
          <div className="form-item">
            <div className="form-labels">
              <Typography size={14} weight="500">
                Frequency
              </Typography>
              <Typography size={12} weight="400" color="solarized">
                {t('bookSessionLimitDesc', {
                  count: getRecurringBookingCreditsCount(),
                })}
              </Typography>
            </div>
            <FlatSelect
              id="flat-select-frequency"
              options={getRecurringSessionOptions()}
              selectedOption={selectedFrequency}
              onChange={(value) => setSelectedFrequency(value)}
            />
          </div>
          {!!selectedFrequency && <div className="form-item">
            <div className="form-labels">
              <Typography size={14} weight="500">
                Select session duration
              </Typography>
              <Typography size={12} weight="400" color="solarized">
                Every 30min costs 1 credit.
              </Typography>
            </div>
            <FlatSelect
              id="flat-select-durations"
              options={
                role === ProviderRoles.COACHING
                  ? coachingSessionDurations
                  : clinicalSessionDurations
              }
              selectedOption={selectedSessionDuration}
              onChange={(value) =>
                setSelectedSessionDuration(value as AvailableSessionDurations)
              }
            />
          </div>}

          {role === ProviderRoles.INTELLECTCLINICOFFSITE &&
            (user.isCareNavigator ? (
              <div className="form-item">
                <Typography size={14} weight="500">
                  {t('location')}
                </Typography>
                <SelectBox
                  fullWidth
                  selectedValue={locationId}
                  values={
                    providerServices
                      ? providerServices.clinics.map((clinic) => ({
                        label: clinic.name,
                        value: clinic.id,
                      }))
                      : []
                  }
                  disabled={isProviderServicesLoading || !providerServices}
                  onChange={(value) => setLocationId(value)}
                />
              </div>
            ) : (
              <div className="form-item">
                <Typography size={14} weight="500">
                  {t('location')}
                </Typography>
                <TextBox
                  id="locationRef"
                  variant="box-border"
                  value={locationRef}
                  onChange={(event) => setLocationRef(event.target.value)}
                  maxLength={40}
                />
              </div>
            ))}

          {!!selectedSessionDuration && <div className="form-item">
            <div className="form-labels">
              <Typography size={14} weight="500">
                Start date
              </Typography>
            </div>
            <DatePicker
              enabledDates={getAvailableSlotsDates()}
              disabledDates={providerLeaveDays}
              onSelect={(date) => setSelectedDate(date)}
            />
          </div>}
          {!!selectedDate && <div className="form-item fixed-container">
            <Typography size={14} weight="500">
              Select a start time ({getTimeZoneAbbreviation()})
            </Typography>
            <SelectList
              options={slots}
              selectedValue={selectedSlot}
              onClick={(newSlot) => setSelectedSlot(newSlot)}
              emptyLabel={{
                show: Boolean(selectedDate) && !slots.length,
                label: 'No available slots, please try a different date.',
              }}
            />
          </div>}

          {summaryLabels.frequency && summaryLabels.sessionsCount && (
            <motion.div
              className="form-item"
              initial={{ scale: 0.25, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
            >
              <div className="detailed-info">
                <Typography size={12} weight="400">
                  {summaryLabels.frequency}
                </Typography>
                <Typography size={16} weight="600">
                  {t('sessionSummary', {
                    day: moment(selectedDate, ApiDateFormat).format('dddd'),
                    time: slots.find((s) => s.value === selectedSlot)?.label,
                    count: summaryLabels.sessionsCount,
                  })}
                </Typography>
              </div>
            </motion.div>
          )}
          {!user.isEmployee && !!selectedSlot && <div className="country-timezone">
            Confirm User<br /> Timezone:
            <SelectBox
              id="filter-country"
              selectedValue={filterData.country.code}
              values={TimezoneByCountry.map((tc) => ({
                label: tc.name,
                value: tc.code,
              }))}
              onChange={(value) => {
                const country = getCountryDataFromCountryCode(
                  value,
                ) as ITimezoneByCountry;
                updateFilterData({
                  filterData: {
                    ...filterData,
                    country,
                    timezone: country.timezones[0],
                  },
                });
              }}
            />
            <SelectBox
              id="filter-country-timezone"
              selectedValue={filterData.timezone}
              values={filterData.country.timezones.map((tt) => ({
                label: tt,
                value: tt,
              }))}
              onChange={(value) => updateFilterData({ filterData: { ...filterData, timezone: value } })}
            />
          </div>}
          <div className="action-container">
            <Button
              width="100%"
              height="40px"
              label="Book a session"
              onClick={() => onSchedule()}
              variant="blue"
              disabled={!selectedDate || !selectedSlot}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default RecurringSessionSchedulePopup;
