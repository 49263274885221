import React from 'react';
import { IProviderMeeting } from '@customTypes/response/meeting';
import Button from '@widgets/Button';
import { useQueryClient } from 'react-query';
import { FetchKeys } from '@constants/queries';
import SessionCancelPopup from '../../SessionCancelPopup';

interface MeetingCancelButtonProps {
  meetingData: IProviderMeeting;
  providerName: string;
}
export function MeetingCancelButton({
  meetingData,
  providerName,
}: MeetingCancelButtonProps) {
  const [showMeetingCancelPopup, setShowMeetingCancelPopup] =
    React.useState<boolean>(false);

  const queryClient = useQueryClient();
  return (
    <>
      <Button
        label="Cancel"
        variant="label"
        onClick={() => setShowMeetingCancelPopup(true)}
      />
      {meetingData && showMeetingCancelPopup ? (
        <SessionCancelPopup
          providerName={providerName || ''}
          meetingId={meetingData.id}
          refetchUserMeeting={() =>
            queryClient.invalidateQueries([FetchKeys.providerDetails])
          }
          onClose={() => setShowMeetingCancelPopup(false)}
        />
      ) : null}
    </>
  );
}
