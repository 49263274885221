import * as React from 'react';
import {
  IProviderMeeting,
  IRoomChangeOption,
} from '@customTypes/response/meeting';
import Typography from '@widgets/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@widgets/Button';
import SelectBox from '@widgets/SelectBox';
import Popup from '@widgets/Popup';
import './UpdateRoom.scss';
import { WeekdayEnum } from '@constants/App';
import moment from 'moment';
import ConfirmationPopup from '@components/SessionSchedule/RescheduleSession/ConfirmationPopup';
import useUpdateRoom from '../hooks/useUpdateRoom';
import useRoomsList from '../hooks/useRoomsList';

interface IUpdateRoom {
  onClose: (canRefetch: boolean, shouldTrack?: boolean) => void;
  meetingData: IProviderMeeting & {
    type: 'cancel' | 'reschedule' | 'update-room';
  };
}

export function UpdateRoom({ onClose, meetingData }: IUpdateRoom) {
  const { t } = useTranslation();

  const [selectedRoom, setSelectedRoom] = React.useState<{
    roomId: string;
    status: IRoomChangeOption['status'];
    name: string;
  }>({
    roomId: meetingData.roomId,
    status: null,
    name: meetingData.room?.name || 'N/A',
  });

  const { data, isLoading } = useRoomsList({
    locationId: meetingData.room?.locationId,
    meetingId: meetingData.id,
  });

  const {
    data: updateRoomSuccessData,
    mutate,
    isSuccess: isRoomUpdationSuccess,
  } = useUpdateRoom({});

  const onSelectedRoomChange = (newRoomId: string) => {
    const roomWithNewRoomId = data?.find((room) => room.id === newRoomId);

    if (roomWithNewRoomId) {
      setSelectedRoom({
        roomId: newRoomId,
        status: roomWithNewRoomId.status,
        name: roomWithNewRoomId.name,
      });
    }
  };

  if (isRoomUpdationSuccess && updateRoomSuccessData) {
    const weekDay = updateRoomSuccessData.day
      ? moment.weekdaysShort(
        WeekdayEnum[updateRoomSuccessData.day as keyof typeof WeekdayEnum],
      )
      : '';
    const formattedDate = updateRoomSuccessData.displayDate
      ? moment(updateRoomSuccessData.displayDate).format('MMM DD')
      : '';
    return (
      <ConfirmationPopup
        rescheduledDateTime={`${weekDay} ${formattedDate} at ${updateRoomSuccessData.displayTime}`}
        location={updateRoomSuccessData?.room}
        handleOkay={() => onClose(true, true)}
      />
    );
  }

  return (
    <Popup showCloseIcon withBackdrop onClose={() => onClose(true, true)}>
      <div className="update-room-container">
        <div className="header">
          <Typography size={24} weight="600">
            {t('UPDATE_LOCATION')}
          </Typography>
        </div>
        <label>
          Location
          <SelectBox
            placeholder={`${meetingData.room?.name || 'N/A'}@${
              meetingData.room?.location.name || 'N/A'
            }`}
            values={[]}
            selectedValue={null}
            disabled
            fullWidth
          />
        </label>
        <label>
          Room
          <SelectBox
            values={
              data?.map((room) => ({ label: room.name, value: room.id })) || []
            }
            selectedValue={selectedRoom.roomId}
            disabled={isLoading || !data}
            placeholder={isLoading ? 'Loading...' : 'Select a room'}
            fullWidth
            onChange={onSelectedRoomChange}
          />
        </label>
        {['2', '3'].includes(selectedRoom.status || '') ? (
          <div className="room-warning">
            <div className="title">
              {t(
                `ROOM_${
                  selectedRoom.status === '2' ? 'SWAP' : 'CONFLICT'
                }_WARNING_TITLE`,
              )}
            </div>
            <div className="subtitle">
              {t(
                `ROOM_${
                  selectedRoom.status === '2' ? 'SWAP' : 'CONFLICT'
                }_WARNING_SUBTITLE`,
                {
                  old_room: meetingData.room?.name,
                  new_room: selectedRoom.name,
                },
              )}
            </div>
          </div>
        ) : null}
        <Button
          variant="blue"
          label={t('CONFIRM_CTA')}
          disabled={
            selectedRoom.roomId === meetingData.roomId ||
            selectedRoom.status === '3'
          }
          onClick={() =>
            mutate({
              meetingId: meetingData.id,
              roomId: meetingData.roomId,
              newRoomId: selectedRoom.roomId,
            })
          }
          fontSize={14}
          width="100%"
          height="40px"
        />
      </div>
    </Popup>
  );
}
