import * as React from 'react';
import moment from 'moment';
import Popup from '@widgets/Popup';
import Typography from '@widgets/Typography';
import ChatAvatar from '@components/Chat/components/ChatAvatarV2';
import './UserRecurringBookingSuccess.scss';
import Tags from '@widgets/Tags';
import clockIcon from '@assets/clock.svg';
import calenderIcon from '@assets/calendar.svg';
import syncIcon from '@assets/sync.svg';
import { RecentChatMessageFormat } from '@utilities/common/Date';
import { IUserRecurringBookingSuccess } from './types';
import CancellationPolicies from './CancellationPolicies';

import { TagText } from './constants';

function UserRecurringBookingSuccess({
  confirmationData,
  closeHandler,
  headerText = 'You’re all set!',
  subHeaderText = 'We’ve sent you the confirmation email for your session',
}: IUserRecurringBookingSuccess) {
  const { providerRole } = confirmationData;

  return (
    <Popup
      withBackdrop
      onClose={closeHandler}
      className="user-recurring-success-modal"
    >
      <article className="recurring-success-container">
        <section className="recurring-success-wrapper">
          <section className="recurring-header">
            <Typography
              size={20}
              weight="600"
              withColor="#0A0C0E"
              textAlign="center"
            >
              {headerText}
            </Typography>
            <Typography size={16} weight="400" color="swamp" textAlign="center">
              {subHeaderText}
            </Typography>
          </section>
          <section className="recurring-session-details">
            <ChatAvatar
              name={confirmationData?.providerName as string}
              profileImage={confirmationData?.profileImage}
            />
            <section className="confiramtion-details">
              {providerRole && (
                <Tags
                  tags={TagText[providerRole as string]}
                  variant="pill"
                  backgroundColor="#E6F7EF"
                  textColor="#020000"
                  textSize={14}
                />
              )}
              <Typography size={26} weight="600">
                {confirmationData?.providerName}
              </Typography>
              <section className="session-info">
                <section className="session-info-item">
                  <section className="session-info-icon">
                    <img src={calenderIcon} alt="calendar-icon" />
                  </section>
                  <Typography size={17} weight="500" withColor="#484F54">
                    Upcoming session on{' '}
                    {moment(confirmationData.nextSessionOn).format(
                      'dddd, MMM DD',
                    )}
                  </Typography>
                </section>
                {Number(confirmationData?.sessionsCount) > 1 && (
                  <section className="session-info-item">
                    <section className="session-info-icon">
                      <img src={syncIcon} alt="sync-icon" />
                    </section>
                    <Typography size={17} weight="500" withColor="#484F54">
                      Repeat
                      {Number(confirmationData.interval) === 3
                        ? ' biweekly '
                        : ' weekly '}
                      for {confirmationData.sessionsCount} sessions (4 credits)
                    </Typography>
                  </section>
                )}
                <section className="session-info-item">
                  <section className="session-info-icon">
                    <img src={clockIcon} alt="clock-icon" />
                  </section>
                  <Typography size={17} weight="500" withColor="#484F54">
                    {moment
                      .unix(Number(confirmationData.scheduledStartTime))
                      .format(RecentChatMessageFormat)}{' '}
                    -
                    {moment
                      .unix(Number(confirmationData.scheduledEndTime))
                      .format(RecentChatMessageFormat)}
                  </Typography>
                </section>
              </section>
            </section>
          </section>
          <CancellationPolicies />
        </section>
      </article>
    </Popup>
  );
}

export default UserRecurringBookingSuccess;
