import * as React from 'react';
import './BookedPopup.scss';
import Popup from '@widgets/Popup';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button';
import moment from 'moment';
import { RecurringSessionSuccessData } from '@components/SessionSchedule/types';
import successIcon from './assets/booked.png';

interface BookedRecurringSessionPopupProps {
  onClose: () => void;
  upcomingSessionOn: string | undefined;
  frequencyLabel: string | undefined;
  sessionsCount: string | undefined;
  location: RecurringSessionSuccessData['location'];
}

function BookedRecurringSessionPopup({
  onClose,
  upcomingSessionOn,
  frequencyLabel,
  sessionsCount,
  location,
}: BookedRecurringSessionPopupProps) {
  const { t } = useTranslation();

  const upcomingSessionMoment = moment(upcomingSessionOn);

  return (
    <Popup showCloseIcon={false} onClose={onClose}>
      <div className="booked-popup">
        <img className="success" src={successIcon} alt="booking successful" />
        <div className="description">
          <Typography size={20} weight="500">
            You’re all set!
          </Typography>
          <div>
            <Typography
              color="secondary"
              size={12}
              weight="600"
              textAlign="center"
            >
              Upcoming session on:
            </Typography>
            <Typography size={16} weight="600">
              {upcomingSessionMoment.format('ddd, MMM D [at] h:mma')}
            </Typography>
          </div>
          <Typography
            size={16}
            weight="400"
            color="secondary"
            textAlign="center"
          >
            {t('confirmationSummary', {
              frequencyLabel,
              count: Number(sessionsCount),
              day: upcomingSessionMoment.format('dddd'),
              time: upcomingSessionMoment.format('h:mma'),
            })}
          </Typography>
          {location ? (
            <div>
              <Typography
                size={16}
                weight="500"
                color="primary"
                textAlign="center"
              >
                Location: {location?.name},
              </Typography>
              <Typography
                size={16}
                weight="500"
                color="primary"
                textAlign="center"
              >
                {location?.location?.name}
              </Typography>
            </div>
          ) : null}
        </div>
        <Button
          label="Okay"
          onClick={() => onClose()}
          variant="blue"
          width="70%"
        />
      </div>
    </Popup>
  );
}

export default BookedRecurringSessionPopup;
