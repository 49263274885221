/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Popup from '@widgets/Popup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Typography from '@widgets/Typography';
import FlatSelect from '@widgets/FlatSelect';
import useLayout from '@hooks/useLayout';
import './UserRecurringSessionModal.scss';
import DatePicker from '@widgets/DatePicker';
import SelectList from '@widgets/SelectList';
import { getTimeZoneAbbreviation } from '@utilities/common/Date';
import RecommendationCard from '@widgets/RecommendationCard';
import infoIcon from '@assets/filledAlert.svg';
import frameImg from '@assets/Frame 1171274954.svg';
import useAuth from '@hooks/useAuth';
import { VideoRoomSDKs } from '@constants/App';
import {
  getAvailableSlots,
  getAvailableSlotsDates,
  getCreditsummary,
  getProviderTags,
  getSessionSummary,
} from '@utilities/common/meeting';
import {
  AvailableSessionDurations,
  IProviderOpenSlotsFullWithFrequency,
} from '@customTypes/response/provider';
import Loader from '@widgets/Loader';
import useTracking from '@hooks/useTracking';
import { EventActions, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import { getBookingSource } from '@utilities/common/user';
import { FrequencyBasedText } from './constant';

import useRecurringProviderSlots from '../hooks/useRecurringProviderSlots';
import useEmployeeDetails from '../hooks/useEmployeeDetails';
import useProviderLeaveDays from '../hooks/useProviderLeaveDays';

import useProviderMatching from '../hooks/useProviderMatching';
import useBooking from '../hooks/useBooking';
import { IUserRecurringSessionForm, IUserRecurringSessionModal } from './types';

const defaultValue: IUserRecurringSessionForm = {
  sessionFrequency: '2',
  sessionDate: '',
  sessionSlot: '',
};

const defaultOptions = [
  {
    label: 'Weekly',
    value: '2',
    indicator: 'Recommended',
    disabled: false,
  },
  {
    label: 'Biweekly',
    value: '3',
    disabled: false,
  },
  { label: 'One-time', value: '1', disabled: false },
];

function UserRecurringSessionModal({
  closeHandler,
  role,
  providerData,
  source,
  isProviderMatched,
  subHeaderText = 'Session duration: 60 minutes',
}: IUserRecurringSessionModal) {
  const {
    control,
    formState: { isValid },
    watch,
    handleSubmit,
  } = useForm<IUserRecurringSessionForm>({
    mode: 'onChange',
    defaultValues: defaultValue,
  });
  const { providerId } = providerData;
  const defaultSessionDuration = '3600';
  const { reloadChat } = useLayout();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { track } = useTracking();
  const loaderParentRef = React.useRef<HTMLElement>(null);

  const { data: employeeData } = useEmployeeDetails(user.id, {
    onSuccess: () => {},
  });
  const { meetingAllowed } = employeeData || {};

  const sessionFrequencyWatcher = watch('sessionFrequency');
  const sessionDateWatcher = watch('sessionDate');
  const sessionSlotWatcher = watch('sessionSlot');

  const { data: providerOpenSlots } =
    useRecurringProviderSlots<IProviderOpenSlotsFullWithFrequency>({
      providerId: providerId.toString(),
      role,
      locationId: null,
      count: meetingAllowed?.[role]?.count?.toString() || '0',
      enabled: !!employeeData,
    });

  const { data: providerLeaveDays } = useProviderLeaveDays(
    providerId.toString(),
  );

  const { isLoading: isMeetingBeingBooked, mutate: bookMeeting } = useBooking(
    user.id,
    {
      role, // TODO: overriding role as 1 since its specific for TikTok
      sessionId: user.sessionId,
      scheduledStartTime: sessionSlotWatcher,
      duration: defaultSessionDuration as AvailableSessionDurations,
      type: VideoRoomSDKs.dailyCo,
      interval: sessionFrequencyWatcher,
      count: sessionFrequencyWatcher === '1' ? '1' : '4',
      source: getBookingSource(user),
    },
    {
      onSuccess: (data) => {
        closeHandler(true, data);
      },

      onError: (e: Error) => {
        toast(e.message);
      },
    },
  );

  const { isLoading: isProviderBeingMatched, mutate: matchProvider } =
    useProviderMatching(
      {
        providerId,
        role, // overriding role as 1 since its specific for TikTok
        userId: user.id,
        tags: getProviderTags(user),
        friendlyName: user.name,
      },
      {
        onSuccess: () => {
          bookMeeting();
          reloadChat?.();
        },
        onError: () => {
          toast(
            'Oops! Something went wrong while trying to match with the provider, please try again!',
          );
        },
      },
    );

  const availableSlotData = React.useMemo(
    () =>
      getAvailableSlots({
        providerOpenSlots,
        selectedFrequency: sessionFrequencyWatcher,
        selectedSessionDuration: defaultSessionDuration,
        selectedDate: sessionDateWatcher,
      }),
    [
      providerOpenSlots,
      sessionFrequencyWatcher,
      defaultSessionDuration,
      sessionDateWatcher,
    ],
  );

  const onConfirm = (data: IUserRecurringSessionForm) => {
    const frequencyLabel = defaultOptions.filter(
      (item) => item.value === data.sessionFrequency,
    )[0].label;
    track(EventNames.confirmSession, {
      source,
      eventAction: EventActions.click,
      eventCategory: frequencyLabel,
      eventLabel: sessionFrequencyWatcher === '1' ? '1' : '4',
      userType: Analytics.getUserType(),
      eventSource: 'virtual',
    });
    if (!isProviderMatched) {
      matchProvider();
    } else {
      bookMeeting();
    }
  };

  return (
    <Popup
      withBackdrop
      onClose={() => closeHandler(false)}
      className="user-recurring-modal"
    >
      <article className="recurring-parent" ref={loaderParentRef}>
        {(isProviderBeingMatched || isMeetingBeingBooked) && (
          <Loader
            useFullPage={false}
            dimension={loaderParentRef.current?.getBoundingClientRect()}
          />
        )}
        <section className="recurring-header">
          <Typography size={18} weight="500" withColor="#1A2631">
            Book a session
          </Typography>
          <Typography size={14} weight="500" color="swamp">
            {subHeaderText}
          </Typography>
        </section>
        {providerData.provider.leave.onLeave && (
          <section className="leave-banner">
            {t('BANNER_TEXT_OOO', {
              date: providerData.provider.leave.to
                ? moment.unix(providerData.provider.leave.to).format('DD MMM')
                : '',
            })}
          </section>
        )}
        <section className="form-wrapper">
          <form onSubmit={handleSubmit(onConfirm)}>
            <section>
              <Typography size={14} weight="500">
                Frequency
              </Typography>
              <Typography size={12} weight="400" withColor="#1A26317A">
                To achieve your client&apos;s goals, we recommend booking weekly
                recurring sessions.
              </Typography>
            </section>
            <section className="frequency-container">
              <Controller
                name="sessionFrequency"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <FlatSelect
                    {...field}
                    id="flat-select-frequency"
                    selectedOption={field.value}
                    options={defaultOptions}
                  />
                )}
              />
              <RecommendationCard
                icon={infoIcon}
                headerText={
                  FrequencyBasedText[sessionFrequencyWatcher]
                    .RecommendationCardText
                }
                variant={sessionFrequencyWatcher === '2' ? 'green' : 'orange'}
              />
            </section>
            <section className="date-container">
              <label className="form-label" htmlFor="sessionDate">
                Session date
              </label>
              <Controller
                name="sessionDate"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    enabledDates={getAvailableSlotsDates({
                      providerOpenSlots,
                      selectedFrequency: sessionFrequencyWatcher,
                      selectedSessionDuration: defaultSessionDuration,
                    })}
                    disabledDates={providerLeaveDays}
                    onSelect={(date) => field.onChange(date)}
                  />
                )}
              />
            </section>
            {sessionDateWatcher && (
              <section className="slot-container">
                <label className="form-label" htmlFor="sessionSlot">
                  Select a start time ({getTimeZoneAbbreviation()})
                </label>
                <Controller
                  name="sessionSlot"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) =>
                      availableSlotData.filter((slot) => slot.value === value)
                        .length > 0,
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <SelectList
                      {...field}
                      options={availableSlotData}
                      selectedValue={field.value}
                      onClick={(slot) => field.onChange(slot)}
                      emptyLabel={{
                        show: !!sessionDateWatcher && !availableSlotData.length,
                        label:
                          'No available slots, please try a different date.',
                      }}
                    />
                  )}
                />
              </section>
            )}
            <section className="repeat-card">
              <img src={frameImg} alt="frame-img" />
              <section className="repeat-card-front">
                <Typography
                  size={12}
                  weight="400"
                  textAlign="center"
                  withColor="#0A0C0E"
                >
                  {FrequencyBasedText[sessionFrequencyWatcher].RepeatCardText}
                  {!!sessionDateWatcher && !!sessionSlotWatcher && ' on'}
                </Typography>
                <Typography
                  size={16}
                  weight="600"
                  textAlign="center"
                  withColor="#0A0C0E"
                >
                  {getSessionSummary({
                    date: sessionDateWatcher,
                    slot: sessionSlotWatcher,
                    duration: defaultSessionDuration,
                    isValid,
                  })}
                </Typography>
                <Typography
                  size={12}
                  weight="400"
                  textAlign="center"
                  withColor="#0A0C0E"
                >
                  {getCreditsummary({
                    slot: sessionSlotWatcher,
                    sessionCount: sessionFrequencyWatcher === '1' ? 1 : 4,
                    duration: defaultSessionDuration,
                    isValid,
                  })}
                </Typography>
              </section>
            </section>
            <section>
              <input
                className="confirm-button"
                value="Confirm"
                type="submit"
                disabled={!isValid}
              />
            </section>
          </form>
        </section>
      </article>
    </Popup>
  );
}

export default UserRecurringSessionModal;
